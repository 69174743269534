export const FETCH_PROGRAM_START = 'FETCH_PROGRAM_START'
export const FETCH_PROGRAM_SUCCESS = 'FETCH_PROGRAM_SUCCESS'

export const UPDATE_PROGRAM = 'UPDATE_PROGRAM'
export const UPDATE_PROGRAM_SUCCESS = 'UPDATE_PROGRAM_SUCCESS'

export const CREATE_PROGRAM = 'CREATE_PROGRAM'
export const CREATE_PROGRAM_SUCCESS = 'CREATE_PROGRAM_SUCCESS'

export const FETCH_NEXT_PROGRAM_PAGE_START = 'FETCH_NEXT_PROGRAM_PAGE_START'
export const FETCH_NEXT_PROGRAM_PAGE_SUCCESS = 'FETCH_NEXT_PROGRAM_PAGE_SUCCESS'
