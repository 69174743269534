import request from 'utils/request'

const subdomain = {
  fetch(params = null) {
    let url = '/subdomains'
    if (params) url += `?${params}`

    return request
        .get(url)
        .then((r) => r.data)
  },
  nextPage(page, params = null) {
    let url = `subdomains?page=${page}`
    if (params) url += `&${params}`

    return request
      .get(url)
      .then((r) => r.data)
  },
}

export default subdomain
