import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { CardHeader, Container, Row } from "reactstrap"
import { Card, Tabs } from "antd"
import HeaderQuickPage from "../../components/Headers/HeaderQuickPage"
import { QuickPageStyle } from "../../css/quickPage.style"
import { createStructuredSelector } from "reselect"
import makeSelectTool from "../../selectors/toolSelector"
import makeSelectProgram from "../../selectors/programSelector";
import QuickPageSteps from "./QuickPageSteps";

const { TabPane } = Tabs


class QuickPage extends React.Component {
  render() {
    return (
      <QuickPageStyle>
        <HeaderQuickPage />
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <div>
                    <h3 className="mb-0">Quick Page</h3>
                  </div>
                </CardHeader>
                <div style={{ padding: '24px' }}>
                  <Tabs defaultActiveKey="1" onChange={(key) => console.log(key)}>
                    <TabPane tab="Create New Program" key="1">
                      <QuickPageSteps
                        tools={this.props.tools}
                        newProgram={true}
                      />
                    </TabPane>
                    <TabPane tab="Use Existing Program" key="2">
                       <QuickPageSteps
                        tools={this.props.tools}
                        programs={this.props.programs}
                        newProgram={false}
                      />
                    </TabPane>
                  </Tabs>
                </div>
              </Card>
            </div>
          </Row>
        </Container>
      </QuickPageStyle>
    )
  }
}

QuickPage.propTypes = {
  dispatch: PropTypes.func,
  tools: PropTypes.any,
  programs: PropTypes.any,
}

const mapStateToProps = createStructuredSelector({
  tools: makeSelectTool(),
  programs: makeSelectProgram(),
})

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuickPage)
