import {
    CREATE_DOMAIN_START,
    FETCH_DOMAIN_START, FETCH_FILTERED_DOMAINS_START,
    UPDATE_DOMAIN_START,
    FETCH_NEXT_DOMAIN_PAGE_START,
} from "../constants/domainConstant"

export function fetchDomains(params) {
    return {
        type: FETCH_DOMAIN_START,
        params
    }
}

export function updateDomain(programUuid, domainUuid, data) {
    return {
        type: UPDATE_DOMAIN_START,
        programUuid,
        domainUuid,
        data
    }
}

export function createDomain(programUuid, data) {
    return {
        type: CREATE_DOMAIN_START,
        programUuid,
        data
    }
}

export function fetchFilteredDomains(programUuidList) {
    return {
        type: FETCH_FILTERED_DOMAINS_START,
        programUuidList,
    }
}

export function fetchNextDomainPage(page, params) {
  return {
    type: FETCH_NEXT_DOMAIN_PAGE_START,
    page,
    params,
  }
}
