import styled from "styled-components"

export const GeneralSubmissionDiv = styled.div`
  .ant-table-wrapper {
    padding: 0 24px;
  }

  .moreActionIcon {
    font-size: 18px;
    margin-left: 12px;
  }

  .anticon-copy {
    font-size: 12px;
    font-weight: bold;
  }

  .w-md-editor-toolbar {
    ul {
      height: 20px;

      li {
        button {
          width: 20px;
        }
      }
    }
  }
`

export const SubmissionDiv = styled.div`
  padding-left: 20px;

  .saveButton, .closeButton, .duplicateButton {
    width: 200px;
    float: right;
    margin: 52px 16px 16px;
  }

  .duplicateButton {
    background-color: #D87C00;
    border-color: #D87C00;
    color: white;
  }

  .empty-div {
    text-align: center;
    padding-top: 30%;
  }

  .modal-sub-div {
    float: left;
    &.first {
        width: 40%;
    }
    
    &.second {
        min-height: 550px;
        width: 59%;
        box-shadow: 15px 20px 50px 15px grey;
        padding: 24px;
        border-radius: 4px;
        margin-bottom: 24px;
    }
    
    .ant-select, .ant-input {
        width: 95%;
    }
    
    .sub {
        margin-top: 8px;
        > textarea, > input {
            margin-bottom: 4px;
        }
    }
    
    pre {
        padding-left: 40px;
    }
    
    .replication-steps-params {
        h2 {
            margin-top: 16px;
        }
    }
  }
`
