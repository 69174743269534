/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index.js"
import Subdomains from "views/examples/Subdomains.js"
import Domains from "views/examples/Domains.js"
import Register from "views/examples/Register.js"
import Login from "views/examples/Login.js"
import Programs from "views/examples/Programs.js"
import Scans from "views/examples/Scans.js"
import Vulnerabilities from "views/examples/Vulnerabilities.js"
import Submission from "views/examples/Submission.js"
import QuickPage from "views/examples/QuickPage.js"

const routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
  },
  {
    path: "/programs",
    name: "Programs",
    icon: "ni ni-building text-red",
    component: Programs,
    layout: "/admin",
  },
  {
    path: "/domains",
    name: "Domains",
    icon: "ni ni-shop text-pink",
    component: Domains,
    layout: "/admin",
  },
  {
    path: "/subdomains",
    name: "Subdomains",
    icon: "fas fa-bullseye text-orange",
    component: Subdomains,
    layout: "/admin",
  },
  {
    path: "/scans",
    name: "Scans",
    icon: "ni ni-compass-04 text-blue",
    component: Scans,
    layout: "/admin",
  },
  {
    path: "/bugs",
    name: "Vulnerabilities",
    icon: "ni ni-spaceship text-red",
    component: Vulnerabilities,
    layout: "/admin",
  },
  {
    path: "/tools",
    name: "Tools",
    icon: "ni ni-app text-info",
    component: Register,
    layout: "/auth",
  },
  {
    path: "/submissions",
    name: "Submission Craft",
    icon: "ni ni-delivery-fast text-green",
    component: Submission,
    layout: "/admin",
  },
  {
    path: "/quick-page",
    name: "Quick Page",
    icon: "ni ni-user-run text-red",
    component: QuickPage,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-single-copy-04 text-warning",
    component: Login,
    layout: "/auth",
  },
]

export default routes
