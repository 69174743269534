const helpers = {
  handleApiErrors(err) {
    if (err.response.status === 401) {
      localStorage.token = '';
      window.location.href = '/auth/login';
    } else if (err.response.status === 403) {
      window.location.href = '/';
    }
  },
};

export default helpers;
