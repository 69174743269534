import styled, { css } from "styled-components"
import { Input, Tag } from "antd"

export const StyledTag = styled(Tag)`
  height: 32px;
  
  svg {
    margin-bottom: 4px;
  }
  
  span {
    line-height: 28px;
  }
  
  ${props => props.type === 'program' && css`
    svg {
      color: purple;
    }
  `}
  
  ${props => props.type === 'domain' && css`
    svg {
      color: blue;
    }
  `}

  ${props => props.type === 'tool' && css`
    svg {
      color: gold;
    }
  `}
`

export const StyledInput = styled(Input)`
  top: 8px;
  border-radius: 4px;
  margin-bottom: 16px !important;
`
