import { takeEvery, call, put, all } from 'redux-saga/effects'
import { notification } from "antd"
import program from "../libs/program"
import {
  CREATE_PROGRAM,
  CREATE_PROGRAM_SUCCESS, FETCH_NEXT_PROGRAM_PAGE_START, FETCH_NEXT_PROGRAM_PAGE_SUCCESS,
  FETCH_PROGRAM_START, FETCH_PROGRAM_SUCCESS,
  UPDATE_PROGRAM, UPDATE_PROGRAM_SUCCESS
} from "../constants/programConstant"


export function* fetchPrograms(data) {
  try {
    const response = yield call(program.fetch, data.filters)

    yield put({
      type: FETCH_PROGRAM_SUCCESS,
      payload: response,
    })
  } catch (error) {
    notification['error']({
      message: 'Unexpected error',
    })
  }
}

export function* fetchNextProgramPage(data) {
  try {
    const response = yield call(program.nextPage, data.page, data.params)

    yield put({
      type: FETCH_NEXT_PROGRAM_PAGE_SUCCESS,
      payload: response,
    })
  } catch (error) {
    notification['error']({
      message: 'Unexpected error',
    })
  }
}

export function* updateProgram(data) {
  try {
    const response = yield call(program.update, data.editingProgramUuid, data.data)

    yield put({
      type: UPDATE_PROGRAM_SUCCESS,
      payload: response,
    })
  } catch (error) {
    notification['error']({
      message: 'Unexpected error',
    })
  }
}

export function* createProgram(data) {
  try {
    const response = yield call(program.create, data.data)

    yield put({
      type: CREATE_PROGRAM_SUCCESS,
      payload: response,
    })
  } catch (error) {
    notification['error']({
      message: 'Unexpected error',
    })
  }
}

export function* watchFetchPrograms() {
  yield takeEvery(FETCH_PROGRAM_START, fetchPrograms)
}

export function* watchFetchNextProgramPage() {
  yield takeEvery(FETCH_NEXT_PROGRAM_PAGE_START, fetchNextProgramPage)
}

export function* watchUpdateProgram() {
  yield takeEvery(UPDATE_PROGRAM, updateProgram)
}

export function* watchCreateProgram() {
  yield takeEvery(CREATE_PROGRAM, createProgram)
}

export default function* rootSaga() {
  yield all([
      watchFetchPrograms(),
      watchFetchNextProgramPage(),
      watchUpdateProgram(),
      watchCreateProgram(),
  ])
}
