import styled from "styled-components"

export const QuickPageStyle = styled.div`
  .add-new-domain-div {
    cursor: pointer;

    .anticon-plus-circle {
      -webkit-animation:fontbulger 2s infinite;
      -moz-animation:fontbulger 2s infinite;
      -o-animation:fontbulger 2s infinite;
      animation:fontbulger 2s infinite;
    }
  }
  
  @-webkit-keyframes fontbulger  {
    0%, 100% {
      font-size:14px;
    }
   
    50% {
      font-size:18px;
    }
  }
    
  @-moz-keyframes fontbulger  {
    0%, 100% {
      font-size:14px;
    }
   
    50% {
      font-size:18px;
    }
  }
    
  @-o-keyframes fontbulger  {
    0%, 100% {
      font-size:14px;
    }
       
    50% {
      font-size:18px;
    }
  }
    
  @keyframes fontbulger  {
    0%, 100% {
      font-size:14px;
    }
       
    50% {
      font-size:18px;
    }
  }
`
