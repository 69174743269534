import {
    FETCH_NEXT_VULNERABILITY_PAGE_START,
    FETCH_VULNERABILITY_START
} from "../constants/vulnerabilityConstant"

export function fetchVulnerabilities(params) {
  return {
    type: FETCH_VULNERABILITY_START,
    params,
  }
}

export function fetchNextVulnerabilityPage(page, params) {
  return {
    type: FETCH_NEXT_VULNERABILITY_PAGE_START,
    page,
    params,
  }
}
