export const FETCH_SCAN_START = 'FETCH_SCAN_START'
export const FETCH_SCAN_SUCCESS = 'FETCH_SCAN_SUCCESS'

export const FETCH_NEXT_SCAN_PAGE_START = 'FETCH_NEXT_SCAN_PAGE_START'
export const FETCH_NEXT_SCAN_PAGE_SUCCESS = 'FETCH_NEXT_SCAN_PAGE_SUCCESS'

export const SUBDOMAIN_SCAN_START = 'SUBDOMAIN_SCAN_START'
export const SUBDOMAIN_SCAN_SUCCESS = 'SUBDOMAIN_SCAN_SUCCESS'

export const NUCLEI_SCAN_START = 'NUCLEI_SCAN_START'
export const NUCLEI_SCAN_SUCCESS = 'NUCLEI_SCAN_SUCCESS'

export const FFUF_SCAN_START = 'FFUF_SCAN_START'
export const FFUF_SCAN_SUCCESS= 'FFUF_SCAN_SUCCESS'

export const NUCLEI_SCAN_TO_ALL_SUBDOMAINS_START = 'NUCLEI_SCAN_TO_ALL_SUBDOMAINS_START'
export const NUCLEI_SCAN_TO_ALL_SUBDOMAINS_SUCCESS = 'NUCLEI_SCAN_TO_ALL_SUBDOMAINS_SUCCESS'
