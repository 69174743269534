import { takeEvery, call, put, all } from 'redux-saga/effects'
import { notification } from "antd"
import {
  CREATE_DOMAIN_START,
  CREATE_DOMAIN_SUCCESS,
  FETCH_DOMAIN_START,
  FETCH_DOMAIN_SUCCESS,
  FETCH_FILTERED_DOMAINS_START,
  FETCH_FILTERED_DOMAINS_SUCCESS,
  UPDATE_DOMAIN_START,
  UPDATE_DOMAIN_SUCCESS,
  FETCH_NEXT_DOMAIN_PAGE_SUCCESS,
  FETCH_NEXT_DOMAIN_PAGE_START
} from "../constants/domainConstant"
import domain from "../libs/domain"


export function* fetchDomains(data) {
  try {
    const response = yield call(domain.fetch, data.params)

    yield put({
      type: FETCH_DOMAIN_SUCCESS,
      payload: response,
    })
  } catch (error) {
    notification['error']({
      message: 'Unexpected error',
    })
  }
}

export function* updateDomain(data) {
  try {
    const response = yield call(domain.update, data.programUuid, data.domainUuid, data.data)

    yield put({
      type: UPDATE_DOMAIN_SUCCESS,
      payload: response,
    })
  } catch (error) {
    notification['error']({
      message: 'Unexpected error',
    })
  }
}

export function* createDomain(data) {
  try {
    const response = yield call(domain.create, data.programUuid, data.data)

    yield put({
      type: CREATE_DOMAIN_SUCCESS,
      payload: response,
    })
  } catch (error) {
    notification['error']({
      message: 'Unexpected error',
    })
  }
}

export function* fetchFilteredDomains(data) {
  try {
    const response = yield call(domain.filteredDomains, data.programUuidList)

    yield put({
      type: FETCH_FILTERED_DOMAINS_SUCCESS,
      payload: response,
    })
  } catch (error) {
    notification['error']({
      message: 'Unexpected error',
    })
  }
}

export function* fetchNextDomainPage(data) {
  try {
    const response = yield call(domain.nextPage, data.page, data.params)

    yield put({
      type: FETCH_NEXT_DOMAIN_PAGE_SUCCESS,
      payload: response,
    })
  } catch (error) {
    notification['error']({
      message: 'Unexpected error',
    })
  }
}

export function* watchFetchDomains() {
  yield takeEvery(FETCH_DOMAIN_START, fetchDomains)
}

export function* watchUpdateDomain() {
  yield takeEvery(UPDATE_DOMAIN_START, updateDomain)
}

export function* watchCreateDomain() {
  yield takeEvery(CREATE_DOMAIN_START, createDomain)
}

export function* watchFetchFilteredDomains() {
  yield takeEvery(FETCH_FILTERED_DOMAINS_START, fetchFilteredDomains)
}

export function* watchFetchNextDomainPage() {
  yield takeEvery(FETCH_NEXT_DOMAIN_PAGE_START, fetchNextDomainPage)
}

export default function* rootSaga() {
  yield all([
      watchFetchDomains(),
      watchUpdateDomain(),
      watchCreateDomain(),
      watchFetchFilteredDomains(),
      watchFetchNextDomainPage()
  ])
}
