
/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react"
import {
  Table, Button, Space, Input as AntInput, Select, Modal, Checkbox
} from 'antd'

import 'antd/dist/antd.css'

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row
} from "reactstrap"
// core components
import HeaderDomains from "components/Headers/HeaderDomains.js"
import moment from "moment"
import PropTypes from "prop-types"
import {createStructuredSelector} from "reselect"
import {connect} from "react-redux"
import makeSelectDomain from "../../selectors/domainSelector"
import {
  fetchDomains
} from "../../actions/domainActions"
import {EditOutlined} from "@ant-design/icons"
import makeSelectProgram from "../../selectors/programSelector"
import { updateDomain, fetchNextDomainPage } from "../../actions/domainActions"
import addSortedInfo from "../../utils/addSortParams"
import SearchFilter from "./SearchFilter"
import getParams from "../../utils/getParams"

const { Option } = Select


class Domains extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      sortedInfo: null,
      editingDomain: null,
      editingDomainModalShown: false,
      searchFilter: false,
      pagination: {
        current: 1,
        total: 0,
        pageSize: 11,
      },
      tags: [],
    }
  }

  componentDidMount() {
    let filters = this.props.location.search.replace('?', '')
    filters = addSortedInfo(filters, this.state.sortedInfo)

    if (this.state.pagination.current) {
      this.props.dispatch(fetchNextDomainPage(this.state.pagination.current, filters))
    } else {
      this.props.dispatch(fetchDomains(filters))
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    if (pagination.current > Math.round(this.state.pagination.count / 10)) return

    this.setState({
      sortedInfo: sorter,
      pagination: {
        ...this.state.pagination,
        current: pagination.current
      },
    }, () => {
      const params = getParams(this.state.tags, this.state.sortedInfo, this.props.location, filters)

      this.props.dispatch(fetchNextDomainPage(pagination.current, params))
    })
  }

  onEditIconClick = (editingUuid) => {
    const editingDomain = this.props.domains.domains.find((p) => p.uuid === editingUuid)

    this.setState({
      editingDomain: editingDomain,
      editingDomainModalShown: true,
    })
  }

  onEditingDomainUrlChanged = (e) => {
    this.setState({
      editingDomain: {
        ...this.state.editingDomain,
        url: e.target.value,
      },
    })
  }

  onEditingDomainProgramChanged = (selected) => {
    this.setState({
      editingDomain: {
        ...this.state.editingDomain,
        program: {
          ...this.state.editingDomain.program,
          uuid: selected,
        }
      }
    })
  }

  onEditingDomainBountyChange = (e) => {
    this.setState({
      editingDomain: {
        ...this.state.editingDomain,
       bounty: e.target.checked,
      }
    })
  }

  onEditingDomainFullScopeChange = (e) => {
    this.setState({
      editingDomain: {
        ...this.state.editingDomain,
       full_scope: e.target.checked,
      }
    })
  }

  handleUpdateDomain = () => {
    const domain = this.props.domains.domains.find((d) => d.uuid === this.state.editingDomain.uuid)
    const data = {
      full_scope: this.state.editingDomain.full_scope,
      bounty: this.state.editingDomain.bounty,
      url: this.state.editingDomain.url,
      program: this.state.editingDomain.program.uuid
    }
    this.props.dispatch(updateDomain(domain.program.uuid, this.state.editingDomain.uuid, data))

    this.setState({
      editingDomain: null,
      editingDomainModalShown: false,
    })
  }

  clearFilters = () => {
    if (this.props.location.search) {
      window.location.href = '/admin/domains'
    }

    this.setState({
      searchFilter: false,
    })
  }

  clearAll = () => {
    if (this.props.location.search) {
      window.location.href = '/admin/scans'
    }

    this.setState({
      sortedInfo: null,
      pagination: {
        current: 1,
        total: 0,
      },
      tags: [],
    }, () => { this.props.dispatch(fetchDomains()) })
  }

  editingDomainModalClose = () => {
    this.setState({
      editingDomain: null,
      editingDomainModalShown: false,
    })
  }

  setTags = (tags) => {
    this.setState({
      tags,
    })
  }

  render() {
    const { pagination } = this.state
    if (this.props.domains.count !== pagination.total) pagination.total = this.props.domains.count

    let { sortedInfo } = this.state
    sortedInfo = sortedInfo || {}

    const columns = [
      {
        title: 'Domain',
        dataIndex: 'url',
        key: 'domain',
        ellipsis: true,
      },
      {
        title: 'Program',
        dataIndex: 'program',
        key: 'program',
        ellipsis: true,
        render: (text) => <a href={`/admin/programs?program=${text.uuid}`}>{text.name}</a>,
      },
      {
        title: 'Subdomain',
        dataIndex: 'subdomain',
        key: 'subdomain',
        sorter: (a, b) => a.subdomain.count - b.subdomain.count,
        sortOrder: sortedInfo.columnKey === 'subdomain' && sortedInfo.order,
        render: (text) => <a href={`/admin/subdomains?domain=${text.url}`}>{text.count}</a>,
      },
      {
        title: 'Vulnerabilities',
        dataIndex: 'vulnerability',
        key: 'vulnerability',
        sorter: (a, b) => a.vulnerability.count - b.vulnerability.count,
        sortOrder: sortedInfo.columnKey === 'vulnerability' && sortedInfo.order,
        ellipsis: true,
        render: (text) => <a href={`/admin/bugs/?domain=${text.url}`}>{text.count}</a>,
      },
      {
        title: 'Bounty',
        dataIndex: 'bounty',
        key: 'bounty',
        sorter: (a, b) => a.bounty - b.bounty,
        sortOrder: sortedInfo.columnKey === 'bounty' && sortedInfo.order,
        ellipsis: true,
      },
      {
        title: 'Full Scope',
        dataIndex: 'full_scope',
        key: 'full_scope',
        sorter: (a, b) => a.full_scope - b.full_scope,
        sortOrder: sortedInfo.columnKey === 'full_scope' && sortedInfo.order,
        ellipsis: true,
      },
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        sorter: (a, b) => moment(a.date) - moment(b.date),
        sortOrder: sortedInfo.columnKey === 'date' && sortedInfo.order,
        ellipsis: true,
        render: (text) => moment(text).format('YYYY/MM/DD - HH:mm')
      },
      {
        title: 'Action',
        dataIndex: 'uuid',
        key: 'edit',
        ellipsis: true,
        render: (text) => <div style={{ cursor: 'pointer' }} onClick={() => this.onEditIconClick(text)}><EditOutlined /></div>
      },
    ]

    return (
      <>
        <Modal
          title='Update Domain'
          visible={this.state.editingDomainModalShown}
          onOk={this.handleUpdateDomain}
          onCancel={() => this.editingDomainModalClose()}
          okText='Update'
          okButtonProps={{
            disabled: this.state.editingDomain && (
                !this.state.editingDomain.url || !this.state.editingDomain.program.uuid),
          }}
        >
          <div>
            <div className='modal-sub-div'>
              <span>Program</span><br/>
              <Select
                showSearch
                style={{ width: '100%' }}
                optionFilterProp='children'
                onChange={this.onEditingDomainProgramChanged}
                defaultValue={this.state.editingDomain ? this.state.editingDomain.program.uuid : null}
                value={this.state.editingDomain ? this.state.editingDomain.program.uuid : null}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {
                  this.props.programs.programs.map((program, index) => {
                    return (
                        <Option key={index} value={program.uuid}>{program.name}</Option>
                    )
                  })
                }
              </Select>
            </div>
            <div className='modal-sub-div'>
              <span>URL</span>
              <AntInput
                value={this.state.editingDomain ? this.state.editingDomain.url : null}
                onChange={this.onEditingDomainUrlChanged}
              />
            </div>
            <div className='modal-sub-div'>
              <Checkbox
                onChange={this.onEditingDomainBountyChange}
                checked={this.state.editingDomain ? this.state.editingDomain.bounty : false}
              >
                Bounty
              </Checkbox>

              <Checkbox
                onChange={this.onEditingDomainFullScopeChange}
                checked={this.state.editingDomain ? this.state.editingDomain.full_scope : false}
              >
                Full Scope
              </Checkbox>
            </div>
          </div>
        </Modal>

        <HeaderDomains />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Domains table</h3>
                  <br/>
                  <Row style={{ marginTop: '20px' }}>
                    <SearchFilter tags={this.state.tags} setTags={this.setTags} fetch={fetchDomains} sortedInfo={this.state.sortedInfo} />
                  </Row>
                </CardHeader>
                <Space style={{ marginBottom: 16, marginLeft: 24 }}>
                  <Button onClick={this.clearFilters}>Clear filters</Button>
                  <Button onClick={this.clearAll}>Clear filters and sorters</Button>
                </Space>
                <Table
                  columns={columns}
                  pagination={pagination}
                  loading={this.props.domains.loading}
                  dataSource={(() => {
                    const domains = this.props.domains.domains || []
                    return domains.map((domain, index) => {
                      return {
                        key: index,
                        date: domain.created_at,
                        subdomain: {
                          url: domain.url,
                          count: domain.subdomains,
                        },
                        vulnerability: {
                          url: domain.url,
                          count: domain.vulnerabilities,
                        },
                        ...domain,
                        bounty: domain.bounty === true ? 'Present' : 'No',
                        full_scope: domain.full_scope === true ? 'Yes' : 'No',
                      }
                    })
                  })()}
                  onChange={this.handleTableChange}
                />
              </Card>
            </div>
          </Row>
        </Container>
      </>
    )
  }
}

Domains.propTypes = {
  dispatch: PropTypes.func,
  domains: PropTypes.any,
  programs: PropTypes.any,
}

const mapStateToProps = createStructuredSelector({
  domains: makeSelectDomain(),
  programs: makeSelectProgram(),
})

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Domains)
