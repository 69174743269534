import { createSelector } from 'reselect'

const selectUserDomain = () => (state) => state.get('tools')

/**
 * Other specific selectors
 */

const makeSelectTool = () => createSelector(
  selectUserDomain(),
  (substate) => substate.toJS()
)

export default makeSelectTool
export {
  selectUserDomain,
}
