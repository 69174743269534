/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react"
import { Table, Button, Space, Switch, Modal } from 'antd'
import 'antd/dist/antd.css'

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
} from "reactstrap"
// core components
import HeaderSubdomains from "components/Headers/HeaderSubdomains.js"
import PropTypes from "prop-types"
import { createStructuredSelector } from "reselect"
import { connect } from "react-redux"
import makeSelectSubdomain from "../../selectors/subdomainSelector"
import {
  fetchNextSubdomainPage,
  fetchSubdomains
} from "../../actions/subdomainActions"
import moment from "moment"
import SearchFilter from "./SearchFilter"
import addSortedInfo from "../../utils/addSortParams"
import prepareFilterData from "../../utils/filterData"
import NucleiScanParameters from "./NucleiScanParameters"
import { startNucleiScan } from "../../actions/scanActions"
import _ from 'lodash'


class Subdomains extends React.Component {

  state = {
    sortedInfo: null,
    pagination: {
      current: 1,
      total: 0,
    },
    tags: [],
    nucleiScanModalShown: false,
    nucleiScan: {
      dynamic: true,
      url: {
        include: false,
        directory: false,
      },
      templates: [],
      severities: [],
      subdomains: [],
    },
    selectedRowKeys: {
      1: [],
    },
  }

  componentDidMount() {
    const filters = this.props.location.search.replace('?', '')
    this.props.dispatch(fetchSubdomains(filters))
  }

  handleTableChange = (pagination, filters, sorter) => {
    if (pagination.current > Math.round(this.state.pagination.count / 10)) return

    const selectedRowKeys = this.state.selectedRowKeys
    if (!selectedRowKeys[pagination.current]) {
      selectedRowKeys[pagination.current] = []
    }

    this.setState({
      sortedInfo: sorter,
      pagination: {
        ...this.state.pagination,
        current: pagination.current
      },
      selectedRowKeys,
    }, () => {
      let params = ''
      if (this.state.tags.length) {
        params = prepareFilterData(this.state.tags, this.state.sortedInfo)
      } else if (this.props.location.search) {
        params = this.props.location.search.replace('?', '')
      }

      params = addSortedInfo(params, this.state.sortedInfo)

      this.props.dispatch(fetchNextSubdomainPage(pagination.current, params))
    })
  }

  handleAddNewNucleiScan = () => {
    this.nucleiScanToggle()

    if (!this.state.nucleiScan.subdomains.length) return

    this.props.dispatch(startNucleiScan(this.state.nucleiScan))
  }

  onSelectTableRowChange = (selectedRowKeys) => {
    const stateOfSelectedRowKeys = this.state.selectedRowKeys

    const subdomains = this.state.nucleiScan.subdomains
    const newSubdomains = _.union(subdomains, selectedRowKeys)

    if (stateOfSelectedRowKeys[this.state.pagination.current].length > selectedRowKeys.length) {
      const removedRow = _.difference(stateOfSelectedRowKeys[this.state.pagination.current], selectedRowKeys)
      const indexOfRemovedRow = newSubdomains.findIndex((s) => s === removedRow[0])

      newSubdomains.splice(indexOfRemovedRow, 1)
    }

    stateOfSelectedRowKeys[this.state.pagination.current] = selectedRowKeys

    this.setState({
      nucleiScan: {
        ...this.state.nucleiScan,
        subdomains: newSubdomains
      },
      selectedRowKeys: stateOfSelectedRowKeys,
    })
  }

  onNucleiScanTemplateChange = (value) => {
    this.setState({
      nucleiScan: {
        ...this.state.nucleiScan,
        templates: value,
      },
    })
  }

  onNucleiScanSeverityChange = (selectedList) => {
    this.setState({
      nucleiScan: {
        ...this.state.nucleiScan,
        severities: selectedList,
      },
    })
  }

  onNucleiScanIncludeURLChange = (value) => {
    this.setState({
      nucleiScan: {
        ...this.state.nucleiScan,
        url: {
          ...this.state.nucleiScan.url,
          include: value,
        },
      },
    })
  }

  onNucleiScanIncludeDirectoryChange = (value) => {
    this.setState({
      nucleiScan: {
        ...this.state.nucleiScan,
        url: {
          ...this.state.nucleiScan.url,
          directory: value,
        },
      },
    })
  }

  onNucleiScanDynamicChange = (value) => {
    this.setState({
      nucleiScan: {
        ...this.state.nucleiScan,
        dynamic: value,
      },
    })
  }

  clearFilters = () => {
    if (this.props.location.search) {
      window.location.href = '/admin/subdomains'
    }

    this.setState({
      sortedInfo: null,
      pagination: {
        current: 1,
        total: 0,
      },
      tags: [],
    }, () => { this.props.dispatch(fetchSubdomains()) })
  }

  clearAll = () => {
    this.setState({
      sortedInfo: null,
      tags: [],
    })
  }

  setTags = (tags) => {
    this.setState({
      tags,
    })
  }

  nucleiScanToggle = () => {
    const states = {
      nucleiScanModalShown: !this.state.nucleiScanModalShown,
      selectedRowKeys: {
        1: [],
      },
    }

    if (this.state.nucleiScanModalShown) {
      states.nucleiScan = {
        dynamic: true,
        url: {
          include: false,
          directory: false,
        },
        templates: [],
        severities: [],
        subdomains: [],
      }
    }

    this.setState(states)
  }

  render() {
    const { pagination } = this.state
    if (this.props.subdomains.count !== pagination.total) pagination.total = this.props.subdomains.count

    let { sortedInfo } = this.state
    sortedInfo = sortedInfo || {}
    const columns = [
      {
        title: 'Subdomain',
        dataIndex: 'url',
        key: 'url',
        ellipsis: true,
        width: '250px'
      },
      {
        title: 'Program',
        dataIndex: 'program',
        key: 'program',
        ellipsis: true,
        render: (text) => <a href={`/admin/programs?program=${text.uuid}`}>{text.name}</a>,
      },
      // {
      //   title: 'Techs',
      //   key: 'tags',
      //   dataIndex: 'tags',
      //   render: (tags) => (
      //     <span>
      //       {tags.map(tag => {
      //         let color = 'blue'//tag.length > 5 ? 'geekblue' : 'green'
      //         //if (tag === 'loser') {
      //         //  color = 'volcano'
      //         //}
      //         return (
      //           <a href={`/api/subdomains/?filter=tag:${tag}`}><Tag color={color} key={tag}>
      //             {tag}
      //           </Tag></a>
      //         )
      //       })}
      //     </span>
      //   ),
      //   filters: [
      //     { text: 'PHP', value: 'PHP' },
      //     { text: 'Oracle', value: 'Oracle' },
      //   ],
      //   filteredValue: filteredInfo.tags || null,
      //   onFilter: (value, record) => record.tags.includes(value),
      // },
      {
        title: 'Tool',
        dataIndex: 'tool',
        key: 'tool',
        ellipsis: true,
        render: (text) => <a href={`/api/tools/${text.id}`}>{text.name}</a>,
      },
      {
        title: 'Port',
        dataIndex: 'port',
        key: 'port',
        ellipsis: true,
      },
      {
        title: 'Host',
        dataIndex: 'host',
        key: 'host',
        ellipsis: true,
      },
      {
        title: 'Code / Size',
        dataIndex: 'codeSize',
        key: 'codeSize',
        //sorter: (a, b) => a.age - b.age,
        //sortOrder: sortedInfo.columnKey === 'age' && sortedInfo.order,
        ellipsis: true,
        render: (text) => `${text.code ? text.code : 0} / ${text.size ? text.size : 0}`
      },
      {
        title: 'Vulnerabilities',
        dataIndex: 'count',
        key: 'vulnerability',
        ellipsis: true,
        render: (text) => <a href={`/admin/bugs/?subdomainUuid=${text.subdomainUuid}`}>{text.vulnerability}</a>,
      },
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        sorter: (a, b) => moment(a.date) - moment(b.date),
        sortOrder: sortedInfo.columnKey === 'date' && sortedInfo.order,
        ellipsis: true,
        render: (text) => moment(text).format('YYYY/MM/DD - HH:mm'),
        width: '170px'
      },
    ]

    return (
      <>
        <HeaderSubdomains />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Modal
            title='Start Nuclei Scan'
            visible={this.state.nucleiScanModalShown}
            onOk={this.handleAddNewNucleiScan}
            onCancel={() => this.nucleiScanToggle()}
            okText='Start'
          >
            <NucleiScanParameters
              scanState={this.state.nucleiScan}
              onNucleiScanTemplateChange={this.onNucleiScanTemplateChange}
              onNucleiScanSeverityChange={this.onNucleiScanSeverityChange}
              onNucleiScanIncludeURLChange={this.onNucleiScanIncludeURLChange}
              onNucleiScanIncludeDirectoryChange={this.onNucleiScanIncludeDirectoryChange}
            />

            <div className="modal-sub-div">
              Dynamic scan is <Switch checked={this.state.nucleiScan.dynamic} onChange={this.onNucleiScanDynamicChange} />
            </div>
          </Modal>

          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Subdomain List</h3>
                  <br/>
                  <Row>
                    <SearchFilter tags={this.state.tags} setTags={this.setTags} fetch={fetchSubdomains} sortedInfo={this.state.sortedInfo} />
                  </Row>
                </CardHeader>
                <Space style={{ marginBottom: 16, marginLeft: 24 }}>
                  <Button onClick={this.clearFilters}>Clear filters</Button>
                  <Button onClick={this.clearAll}>Clear filters and sorters</Button>
                  <Button
                    type="primary"
                    danger
                    onClick={this.nucleiScanToggle}
                    disabled={!this.state.nucleiScan.subdomains.length}
                  >
                    Scan With Nuclei
                  </Button>
                </Space>
                <Table
                  columns={columns}
                  pagination={pagination}
                  loading={this.props.subdomains.loading}
                  style={{ padding: '24px' }}
                  rowSelection={(() => {
                    return {
                      selectedRowKeys: this.state.nucleiScan.subdomains,
                      onChange: this.onSelectTableRowChange,
                    }
                  })()}
                  dataSource={(() => {
                    const subdomains = this.props.subdomains.subdomains || []
                    return subdomains.map((subdomain, index) => {
                      return {
                        key: subdomain.uuid,
                        date: subdomain.created_at,
                        ...subdomain,
                        count: {
                          vulnerability: subdomain.vulnerability,
                          subdomainUuid: subdomain.uuid
                        },
                        codeSize: {
                          code: subdomain.status_code,
                          size: subdomain.content_length,
                        }
                      }
                    })
                  })()}
                  onChange={this.handleTableChange}
                />
              </Card>
            </div>
          </Row>
        </Container>
      </>
    )
  }
}

Subdomains.propTypes = {
  dispatch: PropTypes.func,
  subdomains: PropTypes.any,
}

const mapStateToProps = createStructuredSelector({
  subdomains: makeSelectSubdomain(),
})

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Subdomains)
