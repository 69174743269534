import { takeEvery, call, put, all } from 'redux-saga/effects'
import { notification } from "antd"
import {
  FETCH_NEXT_SCAN_PAGE_START,
  FETCH_NEXT_SCAN_PAGE_SUCCESS,
  FETCH_SCAN_START,
  FETCH_SCAN_SUCCESS,
  FFUF_SCAN_START,
  FFUF_SCAN_SUCCESS,
  NUCLEI_SCAN_START,
  NUCLEI_SCAN_SUCCESS,
  NUCLEI_SCAN_TO_ALL_SUBDOMAINS_START,
  NUCLEI_SCAN_TO_ALL_SUBDOMAINS_SUCCESS,
  SUBDOMAIN_SCAN_START,
  SUBDOMAIN_SCAN_SUCCESS
} from "../constants/scanConstant"
import scan from "../libs/scan"


export function* fetchScans(data) {
  try {
    const response = yield call(scan.fetch, data.params)

    yield put({
      type: FETCH_SCAN_SUCCESS,
      payload: response,
    })
  } catch (error) {
    notification['error']({
      message: 'Unexpected error',
    })
  }
}

export function* fetchNextScanPage(data) {
  try {
    const response = yield call(scan.nextPage, data.page, data.params)

    yield put({
      type: FETCH_NEXT_SCAN_PAGE_SUCCESS,
      payload: response,
    })
  } catch (error) {
    notification['error']({
      message: 'Unexpected error',
    })
  }
}

export function* startSubdomainScan(data) {
  try {
    const response = yield call(scan.startSubdomainScan, data.data)

    yield put({
      type: SUBDOMAIN_SCAN_SUCCESS,
      payload: response,
    })
  } catch (error) {
    notification['error']({
      message: 'Unexpected error',
    })
  }
}

export function* startNucleiScan(data) {
  try {
    const response = yield call(scan.startNucleiScan, data.data)

    yield put({
      type: NUCLEI_SCAN_SUCCESS,
      payload: response,
    })
  } catch (error) {
    notification['error']({
      message: 'Unexpected error',
    })
  }
}

export function* startFfufScan(data) {
  try {
    const response = yield call(scan.startFfufScan, data.data)

    yield put({
      type: FFUF_SCAN_SUCCESS,
      payload: response,
    })
  } catch (error) {
    notification['error']({
      message: 'Unexpected error',
    })
  }
}

export function* startNucleiScanToAllSubdomains(data) {
  try {
    const response = yield call(scan.startNucleiScanToAllSubdomains, data.data)

    yield put({
      type: NUCLEI_SCAN_TO_ALL_SUBDOMAINS_SUCCESS,
      payload: response,
    })
  } catch (error) {
    notification['error']({
      message: 'Unexpected error',
    })
  }
}

export function* watchFetchScans() {
  yield takeEvery(FETCH_SCAN_START, fetchScans)
}

export function* watchFetchNextScanPage() {
  yield takeEvery(FETCH_NEXT_SCAN_PAGE_START, fetchNextScanPage)
}

export function* watchStartSubdomainScan() {
  yield takeEvery(SUBDOMAIN_SCAN_START, startSubdomainScan)
}

export function* watchStartNucleiScan() {
  yield takeEvery(NUCLEI_SCAN_START, startNucleiScan)
}

export function* watchStartFfufScan() {
  yield takeEvery(FFUF_SCAN_START, startFfufScan)
}

export function* watchStartNucleiScanToAllSubdomains() {
  yield takeEvery(NUCLEI_SCAN_TO_ALL_SUBDOMAINS_START, startNucleiScanToAllSubdomains)
}

export default function* rootSaga() {
  yield all([
      watchFetchScans(),
      watchFetchNextScanPage(),
      watchStartSubdomainScan(),
      watchStartNucleiScan(),
      watchStartFfufScan(),
      watchStartNucleiScanToAllSubdomains(),
  ])
}
