import axios from 'axios';
import helpers from 'utils/helpers';
import wait from 'utils/wait';

// Default config options
const defaultOptions = {
  baseURL: process.env.REACT_APP_API_URL,
  headers: { 'Content-Type': 'application/json' },
};

// Create request
const request = axios.create(defaultOptions);

// Request Interceptors
request.interceptors.request.use((cfg) => {
  // Set the AUTH token for any request except login process
  if (!(cfg.data && cfg.data.isLogin)) {
    const token = localStorage.getItem('token');
    cfg.headers.Authorization = token ? `JWT ${token}` : '';
  }

  return cfg;
});

// Response Interceptors
request.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (!error.config.retryCount) { error.config.retryCount = 0 }

    if ((error.config.retryCount < 3) && (error.response.status >= 500)) {
      error.config.retryCount += 1;
      await wait(1000);
      return request.request(error.config);
    }

    helpers.handleApiErrors(error);
    throw error;
  }
);

export default request;
