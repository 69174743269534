import { fromJS } from 'immutable'
import {
  FETCH_FFUF_WORDLIST_SUCCESS,
  FETCH_NUCLEI_TEMPLATES_SUCCESS,
  FETCH_TOOL_START, FETCH_TOOL_SUCCESS
} from "../constants/toolConstant"

const initialState = fromJS({
  tools: [],
  nucleiTemplates: [],
  ffufWordlist: [],
})

export default function toolReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_TOOL_START:
      return state
        .set('tools', [])
    case FETCH_TOOL_SUCCESS:
      return state
        .set('tools', action.payload.results)
    case FETCH_NUCLEI_TEMPLATES_SUCCESS:
      return state
        .set('nucleiTemplates', action.payload)
    case FETCH_FFUF_WORDLIST_SUCCESS:
      return state
        .set('ffufWordlist', action.payload)
    default:
      return state
  }
}
