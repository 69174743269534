/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react"
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import {
  notification,
} from 'antd'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap"
import { loginStart } from '../../actions/userAction'

class Login extends React.Component {
  state = {
    email: null,
    password: null,
  }

  onEmailChange = (e) => {
    this.setState({
      email: e.target.value,
    })
  }

  onPasswordChange = (e) => {
    this.setState({
      password: e.target.value,
    })
  }

  onFormSubmit = () => {
    if (!this.state.email || !this.state.password) {
      notification.error({
        message: 'Error',
        description: "Email or password must be filled!",
      })
    } else {
      this.props.dispatch(loginStart({ username: this.state.email, password: this.state.password }));
    }
  }

  render() {
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-5">
              <div className="text-muted text-center mt-2 mb-3">
                <small>Sign in with</small>
              </div>
              <div className="btn-wrapper text-center">
                <Button
                    className="btn-neutral btn-icon"
                    color="default"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                >
              <span className="btn-inner--icon">
                <img
                    alt="..."
                    src={
                      require("../../assets/img/icons/common/github.svg")
                          .default
                    }
                />
              </span>
                  <span className="btn-inner--text">Github</span>
                </Button>
                <Button
                    className="btn-neutral btn-icon"
                    color="default"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                >
              <span className="btn-inner--icon">
                <img
                    alt="..."
                    src={
                      require("../../assets/img/icons/common/google.svg")
                          .default
                    }
                />
              </span>
                  <span className="btn-inner--text">Google</span>
                </Button>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Or sign in with credentials</small>
              </div>
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83"/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                        placeholder="Email"
                        type="email"
                        autoComplete="new-email"
                        onChange={this.onEmailChange}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open"/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                        placeholder="Password"
                        type="password"
                        autoComplete="new-password"
                        onChange={this.onPasswordChange}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                      className="custom-control-input"
                      id=" customCheckLogin"
                      type="checkbox"
                  />
                  <label
                      className="custom-control-label"
                      htmlFor=" customCheckLogin"
                  >
                    <span className="text-muted">Remember me</span>
                  </label>
                </div>
                <div className="text-center">
                  <Button className="my-4" color="primary" type="button" onClick={this.onFormSubmit}>
                    Sign in
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <a
                  className="text-light"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
              >
                <small>Forgot password?</small>
              </a>
            </Col>
            <Col className="text-right" xs="6">
              <a
                  className="text-light"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
              >
                <small>Create new account</small>
              </a>
            </Col>
          </Row>
        </Col>
      </>
    )
  }
}

Login.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  }
}

export default connect(null, mapDispatchToProps)(Login)
