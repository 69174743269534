import {
  FETCH_PROGRAM_START,
  UPDATE_PROGRAM,
  CREATE_PROGRAM,
  FETCH_NEXT_PROGRAM_PAGE_START,
} from "../constants/programConstant"


export function fetchPrograms(filters) {
  return {
    type: FETCH_PROGRAM_START,
    filters,
  }
}

export function fetchNextProgramPage(page, params) {
  return {
    type: FETCH_NEXT_PROGRAM_PAGE_START,
    page,
    params,
  }
}

export function updateProgram(editingProgramUuid, data) {
  return {
    type: UPDATE_PROGRAM,
    editingProgramUuid,
    data,
  }
}

export function createProgram(data) {
  return {
    type: CREATE_PROGRAM,
    data,
  }
}
