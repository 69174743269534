import request from "../utils/request"

const submission = {
  fetchIssues() {
    return request
      .get('issues')
      .then((r) => r.data)
  },
  fetchStep(name) {
    return request
      .get(`issues/steps/${name}`)
      .then((r) => r.data)
  },
  create(payload) {
    return request
      .post('submissions/', payload)
      .then((r) => r.data)
  },
  fetch(params = null) {
    let url = 'submissions/'
    if (params) url += `?${params}`

    return request
      .get(url)
      .then((r) => r.data)
  },
  nextPage(page, params = null) {
    let url = `submissions/?page=${page}`
    if (params) url += `&${params}`

    return request
      .get(url)
      .then((r) => r.data)
  },
  update(submissionUuid, payload) {
    return request
      .patch(`submissions/${submissionUuid}/`, payload)
      .then((r) => r.data)
  },
}

export default submission
