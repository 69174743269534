import React from "react"
import { Card, CardHeader, Container, Row } from "reactstrap"
import {Button, Modal, Space, Switch, Table, Tag} from "antd"
import SearchFilter from "./SearchFilter"
import addSortedInfo from "../../utils/addSortParams"
import {
  fetchNextVulnerabilityPage, fetchVulnerabilities
} from "../../actions/vulnerabilityActions"
import PropTypes from "prop-types"
import { createStructuredSelector } from "reselect"
import { connect } from "react-redux"
import makeSelectVulnerability from "../../selectors/vulnerabilitySelector"
import moment from "moment"
import HeaderVulnerabilities from "../../components/Headers/HeaderVulnerabilities"
import getParams from "../../utils/getParams"

class Vulnerabilities extends React.Component {
  state = {
    tags: [],
    sortedInfo: null,
    pagination: {
      current: 1,
      total: 0,
    },
    autoRefresh: true,
  }

  componentDidMount() {
    let filters = this.props.location.search.replace('?', '')
    filters = addSortedInfo(filters, this.state.sortedInfo)

    if (this.state.pagination.current) {
        this.props.dispatch(fetchNextVulnerabilityPage(this.state.pagination.current, filters))
      } else {
        this.props.dispatch(fetchVulnerabilities(filters))
      }

    this.vulnerabilityInterval()
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }
  
  handleTableChange = (pagination, filters, sorter) => {
    if (pagination.current > Math.round(this.state.pagination.count / 10)) return

    this.setState({
      sortedInfo: sorter,
      pagination: {
        ...this.state.pagination,
        current: pagination.current
      },
    }, () => {
      const params = getParams(this.state.tags, this.state.sortedInfo, this.props.location, filters)

      this.props.dispatch(fetchNextVulnerabilityPage(pagination.current, params))
    })
  }

  onAutoRefreshChange = (value) => {
    if (value === true) {
      this.vulnerabilityInterval()
    } else {
      clearInterval(this.interval)
    }

    this.setState({
      autoRefresh: value,
    })
  }

  info = (text) => {
    Modal.info({
      title: text,
      onOk() {},
    })
  }

  setTags = (tags) => {
    this.setState({
      tags,
    })
  }

  clearAll = () => {
    if (this.props.location.search) {
      window.location.href = '/admin/bugs'
    }

    this.setState({
      sortedInfo: null,
      pagination: {
        current: 1,
        total: 0,
      },
      tags: [],
    }, () => { this.props.dispatch(fetchVulnerabilities()) })
  }

  vulnerabilityInterval = () => {
    this.interval = setInterval(() => {
      const params = getParams(this.state.tags, this.state.sortedInfo, this.props.location, {})

      if (this.state.pagination.current) {
        this.props.dispatch(fetchNextVulnerabilityPage(this.state.pagination.current, params))
      } else {
        this.props.dispatch(fetchVulnerabilities(params))
      }
    }, 5000)
  }

  render() {
    const { pagination } = this.state
    if (this.props.vulnerabilities.count !== pagination.total) pagination.total = this.props.vulnerabilities.count

    let { sortedInfo } = this.state
    sortedInfo = sortedInfo || {}
    const columns = [
      {
        title: 'Tool',
        dataIndex: 'tool',
        key: 'tool',
        ellipsis: true,
        render: (text) => <a href={`/admin/tools?tool=${text.uuid}`}>{text.name}</a>,
      },
      {
        title: 'Subdomains',
        dataIndex: 'sub_domain',
        key: 'sub_domain',
        ellipsis: true,
        render: (text) => <a href={`/admin/subdomains?uuid=${text.uuid}`}>{text.url}</a>,
        width: '300px'
      },
      {
        title: 'URL',
        dataIndex: 'url',
        key: 'url',
        ellipsis: true,
        width: '300px',
        render: (text) => <div style={{ cursor: 'pointer' }} onClick={() => this.info(text)}>{text}</div>
      },
      {
        title: 'Template',
        dataIndex: 'template',
        key: 'template',
        ellipsis: true,
        width: '250px'
      },
      {
        title: 'Tags',
        key: 'tags',
        dataIndex: 'tags',
        width: '200px',
        render: (tags) => (
          <span>
            {tags && tags.split(',').map(tag => {
              const colors = ['blue', 'geekblue', 'green', 'volcano']
              let color = colors[Math.floor(Math.random() * colors.length)]

              return (
                <Tag color={color} key={tag}>
                  {tag}
                </Tag>
              )
            })}
          </span>
        ),
      },
      {
        title: 'Impact',
        dataIndex: 'impact',
        key: 'impact',
        ellipsis: true,
        sorter: (a, b) => a - b,
        sortOrder: sortedInfo.columnKey === 'impact' && sortedInfo.order,
      },
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        sorter: (a, b) => moment(a.date) - moment(b.date),
        sortOrder: sortedInfo.columnKey === 'date' && sortedInfo.order,
        ellipsis: true,
        render: (text) => moment(text).format('YYYY/MM/DD - HH:mm'),
        width: '170px'
      },
    ]

    return (
      <>
        <HeaderVulnerabilities />
        <Container className="mt--7" fluid>
        {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <div style={{ position: 'absolute', width: '100%', paddingRight: '48px' }}>
                    <h3 className="mb-0" style={{ position: 'absolute' }}>Vulnerabilities</h3>
                    <div style={{ float: 'right' }}>Auto refresh is <Switch checked={this.state.autoRefresh} onChange={this.onAutoRefreshChange} /></div>
                  </div>
                  <br/>
                  <Row style={{ marginTop: '20px' }}>
                    <SearchFilter tags={this.state.tags} setTags={this.setTags} fetch={fetchVulnerabilities} sortedInfo={this.state.sortedInfo} />
                  </Row>
                </CardHeader>
                <Space style={{ marginBottom: 16, marginLeft: 24 }}>
                  <Button onClick={this.clearAll}>Clear filters and sorters</Button>
                </Space>
                <Table
                  columns={columns}
                  pagination={pagination}
                  loading={this.props.vulnerabilities.loading}
                  style={{ padding: '24px' }}
                  dataSource={(() => {
                    const vulnerabilities = this.props.vulnerabilities.vulnerabilities || []
                    return vulnerabilities.map((vulnerability, index) => {
                      return {
                        key: index,
                        ...vulnerability,
                        date: vulnerability.created_at,
                      }
                    })
                  })()}
                  onChange={this.handleTableChange}
                />
              </Card>
            </div>
          </Row>
        </Container>
      </>
    )
  }
}

Vulnerabilities.propTypes = {
  dispatch: PropTypes.func,
  vulnerabilities: PropTypes.any,
}

const mapStateToProps = createStructuredSelector({
  vulnerabilities: makeSelectVulnerability(),
})

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Vulnerabilities)
