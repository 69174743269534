import { createSelector } from 'reselect'

const selectUserDomain = () => (state) => state.get('submissions')

/**
 * Other specific selectors
 */

const makeSelectSubmission = () => createSelector(
  selectUserDomain(),
  (substate) => substate.toJS()
)

export default makeSelectSubmission
export {
  selectUserDomain,
}
