import { combineReducers } from 'redux-immutable'
import userReducer from "./userReducer"
import { LOCATION_CHANGE } from 'react-router-redux'
import { fromJS } from "immutable"
import programReducer from "./programReducer"
import domainReducer from "./domainReducer"
import subdomainReducer from "./subdomainReducer"
import scanReducer from "./scanReducer"
import toolReducer from "./toolReducer"
import vulnerabilityReducer from "./vulnerabilityReducer"
import submissionReducer from "./submissionReducer"

/*
 * routeReducer
 *
 * The reducer merges route location changes into our immutable state.
 * The change is necessitated by moving to react-router-redux@4
 *
 */

// Initial routing state
const routeInitialState = fromJS({
  locationBeforeTransitions: null,
})

/**
 * Merge route into the global application state
 */
function routeReducer(state = routeInitialState, action) {
  switch (action.type) {
    /* istanbul ignore next */
    case LOCATION_CHANGE:
      return state.merge({
        locationBeforeTransitions: action.payload,
      })
    default:
      return state
  }
}

export default function createReducer(asyncReducers){
  return combineReducers({
    route: routeReducer,
    user: userReducer,
    programs: programReducer,
    domains: domainReducer,
    subdomains: subdomainReducer,
    scans: scanReducer,
    tools: toolReducer,
    vulnerabilities: vulnerabilityReducer,
    submissions: submissionReducer,
    ...asyncReducers,
  })
}
