import request from 'utils/request'

const tool = {
  fetch(category) {
    let params = ''
    if (category) {
      params = `?category=${category}`
    }

    return request
      .get(`/tools/${params}`)
      .then((r) => r.data)
  },
  fetchNucleiTemplates(directory = null) {
    const params = directory ? `?directory=${directory}` : ''
    return request
      .get(`templates/nuclei${params}`)
      .then((r) => r.data)
  },
  fetchFfufWordlist() {
    return request
      .get('ffuf/wordlist')
      .then((r) => r.data)
  },
  removeNewNucleiTemplate(file) {
    const formData = new FormData()

    formData.append("file", file.originFileObj)

    return fetch(
      `${process.env.REACT_APP_API_URL}/templates/nuclei/new`,
      {
        method: 'DELETE',
        body: formData,
        headers: new Headers({
          'Authorization': localStorage.getItem('token') ? `JWT ${localStorage.getItem('token')}` : ''
        }),
      }
    )
    .then((response) => response.data)
  },
}

export default tool
