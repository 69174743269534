import React from "react"
import PropTypes from "prop-types"
import {Checkbox, Divider, Select} from "antd"

const { Option } = Select


class ProgramSelection extends React.Component {
  render() {
    return (
      <div className='modal-sub-div' style={this.props.style}>
        {this.props.showTitle && (
          <b>Select programs</b>
        )}
        <br/>
        <Select
          showSearch
          mode={this.props.mode}
          allowClear
          placeholder="Please select"
          style={{ width: '100%' }}
          optionFilterProp='children'
          onChange={this.props.onProgramSelectionChange}
          defaultValue={this.props.selectedPrograms || null}
          value={this.props.selectedPrograms || null}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          dropdownRender={menu => (
            <div>
              {this.props.showSelectAllProgramsCheckbox && (
                <>
                  <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                    <Checkbox
                      checked={this.props.allProgramsChecked}
                      onChange={this.props.onNewScanSelectAllProgramChange}
                    >
                      All programs
                    </Checkbox>
                  </div>
                  <Divider style={{ margin: '4px 0' }} />
                </>
              )}
                {menu}
            </div>
          )}
          maxTagCount={4}
        >
          {
            this.props.programs.map((program, index) => {
              return (
                <Option key={index} value={program.uuid}>{program.name}</Option>
              )
            })
          }
        </Select>
      </div>
    )
  }
}

ProgramSelection.propTypes = {
  programs: PropTypes.any,
  showSelectAllProgramsCheckbox: PropTypes.any,
  allProgramsChecked: PropTypes.any,
  onNewScanSelectAllProgramChange: PropTypes.func,
  onProgramSelectionChange: PropTypes.func,
  selectedPrograms: PropTypes.any,
  mode: PropTypes.any,
  showTitle: PropTypes.any,
  style: PropTypes.any,
}

export default ProgramSelection
