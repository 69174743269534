import { fromJS } from 'immutable'
import {
    CREATE_PROGRAM_SUCCESS, FETCH_NEXT_PROGRAM_PAGE_START, FETCH_NEXT_PROGRAM_PAGE_SUCCESS,
    FETCH_PROGRAM_START, FETCH_PROGRAM_SUCCESS, UPDATE_PROGRAM_SUCCESS
} from "../constants/programConstant"

const initialState = fromJS({
  programs: [],
  loading: false,
  count: 0
})

export default function programReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROGRAM_START:
      return state
        .set('programs', [])
        .set('loading', true)
        .set('count', 0)
    case FETCH_PROGRAM_SUCCESS:
      return state
        .set('programs', action.payload.results)
        .set('loading', false)
        .set('count', action.payload.count)
    case FETCH_NEXT_PROGRAM_PAGE_START:
      return state
        .set('programs', [])
        .set('loading', true)
        .set('count', 0)
    case FETCH_NEXT_PROGRAM_PAGE_SUCCESS:
      return state
        .set('programs', action.payload.results)
        .set('loading', false)
        .set('count', action.payload.count)
    case UPDATE_PROGRAM_SUCCESS:
      return state
        .update('programs', (programs) => {
          const updatingProgramId = programs.findIndex((p) => p.uuid === action.payload.uuid)
          const newProgramsList = programs.slice()

          newProgramsList[updatingProgramId] = action.payload

          return newProgramsList
        })
    case CREATE_PROGRAM_SUCCESS:
      return state
      .update('programs', (programs) => {
        const newPrograms = [...programs, action.payload].sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        return newPrograms
      })
    default:
      return state
  }
}
