import {all, call, put, takeEvery} from "redux-saga/effects"
import submission from "../libs/submission"
import { notification } from "antd"
import {
  CREATE_SUBMISSION_START,
  CREATE_SUBMISSION_SUCCESS,
  FETCH_ISSUE_START,
  FETCH_ISSUE_SUCCESS,
  FETCH_NEXT_SUBMISSION_PAGE_START,
  FETCH_NEXT_SUBMISSION_PAGE_SUCCESS,
  FETCH_STEP_START,
  FETCH_STEP_SUCCESS,
  FETCH_SUBMISSIONS_START,
  FETCH_SUBMISSIONS_SUCCESS,
  UPDATE_SUBMISSION_START,
  UPDATE_SUBMISSION_SUCCESS
} from "../constants/submissionConstant"


export function* fetchIssues() {
  try {
    const response = yield call(submission.fetchIssues)

    yield put({
      type: FETCH_ISSUE_SUCCESS,
      payload: response,
    })
  } catch (error) {
    notification['error']({
      message: 'Unexpected error',
    })
  }
}

export function* fetchStep(data) {
  try {
    const response = yield call(submission.fetchStep, data.name)

    yield put({
      type: FETCH_STEP_SUCCESS,
      payload: response,
    })
  } catch (error) {
    notification['error']({
      message: 'Unexpected error',
    })
  }
}

export function* createSubmission(data) {
  try {
    const response = yield call(submission.create, data.payload)

    yield put({
      type: CREATE_SUBMISSION_SUCCESS,
      payload: response,
    })
  } catch (error) {
    notification['error']({
      message: 'Unexpected error',
    })
  }
}

export function* fetchSubmissions(data) {
  try {
    const response = yield call(submission.fetch, data.params)

    yield put({
      type: FETCH_SUBMISSIONS_SUCCESS,
      payload: response,
    })
  } catch (error) {
    notification['error']({
      message: 'Unexpected error',
    })
  }
}

export function* fetchNextSubmissionPage(data) {
  try {
    const response = yield call(submission.nextPage, data.page, data.params)

    yield put({
      type: FETCH_NEXT_SUBMISSION_PAGE_SUCCESS,
      payload: response,
    })
  } catch (error) {
    notification['error']({
      message: 'Unexpected error',
    })
  }
}

export function* updateSubmission(data) {
  try {
    const response = yield call(submission.update, data.submissionUuid, data.payload)

    yield put({
      type: UPDATE_SUBMISSION_SUCCESS,
      payload: response,
    })
  } catch (error) {
    notification['error']({
      message: 'Unexpected error',
    })
  }
}

export function* watchFetchIssues() {
  yield takeEvery(FETCH_ISSUE_START, fetchIssues)
}

export function* watchFetchStep() {
  yield takeEvery(FETCH_STEP_START, fetchStep)
}

export function* watchCreateSubmission() {
  yield takeEvery(CREATE_SUBMISSION_START, createSubmission)
}

export function* watchFetchSubmissions() {
  yield takeEvery(FETCH_SUBMISSIONS_START, fetchSubmissions)
}

export function* watchFetchNextSubmissionPage() {
  yield takeEvery(FETCH_NEXT_SUBMISSION_PAGE_START, fetchNextSubmissionPage)
}

export function* watchUpdateSubmission() {
  yield takeEvery(UPDATE_SUBMISSION_START, updateSubmission)
}

export default function* rootSaga() {
  yield all([
      watchFetchIssues(),
      watchFetchStep(),
      watchCreateSubmission(),
      watchFetchSubmissions(),
      watchFetchNextSubmissionPage(),
      watchUpdateSubmission(),
  ])
}
