export const FETCH_DOMAIN_START = 'FETCH_DOMAIN_START'
export const FETCH_DOMAIN_SUCCESS = 'FETCH_DOMAIN_SUCCESS'

export const UPDATE_DOMAIN_START = 'UPDATE_DOMAIN_START'
export const UPDATE_DOMAIN_SUCCESS = 'UPDATE_DOMAIN_SUCCESS'

export const CREATE_DOMAIN_START = 'CREATE_DOMAIN_START'
export const CREATE_DOMAIN_SUCCESS = 'CREATE_DOMAIN_SUCCESS'

export const FETCH_FILTERED_DOMAINS_START = 'FETCH_FILTERED_DOMAINS_START'
export const FETCH_FILTERED_DOMAINS_SUCCESS = 'FETCH_FILTERED_DOMAINS_SUCCESS'

export const FETCH_NEXT_DOMAIN_PAGE_START = 'FETCH_NEXT_DOMAIN_PAGE_START'
export const FETCH_NEXT_DOMAIN_PAGE_SUCCESS = 'FETCH_NEXT_DOMAIN_PAGE_SUCCESS'
