import { fromJS } from 'immutable'
import {
  FETCH_NEXT_SUBDOMAIN_PAGE_START,
  FETCH_NEXT_SUBDOMAIN_PAGE_SUCCESS,
  FETCH_SUBDOMAIN_START, FETCH_SUBDOMAIN_SUCCESS
} from "../constants/subdomainConstant"

const initialState = fromJS({
  subdomains: [],
  loading: false,
  next: null,
  previous: null,
  count: 0
})

export default function subdomainReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SUBDOMAIN_START:
      return state
        .set('subdomains', [])
        .set('loading', true)
        .set('next', null)
        .set('previous', null)
        .set('count', 0)
    case FETCH_SUBDOMAIN_SUCCESS:
      return state
        .set('subdomains', action.payload.results)
        .set('loading', false)
        .set('next', action.payload.next)
        .set('previous', action.payload.previous)
        .set('count', action.payload.count)
    case FETCH_NEXT_SUBDOMAIN_PAGE_START:
      return state
        .set('subdomains', [])
        .set('loading', true)
        .set('next', null)
        .set('previous', null)
        .set('count', 0)
    case FETCH_NEXT_SUBDOMAIN_PAGE_SUCCESS:
      return state
        .set('subdomains', action.payload.results)
        .set('loading', false)
        .set('next', action.payload.next)
        .set('previous', action.payload.previous)
        .set('count', action.payload.count)
    default:
      return state
  }
}
