import { fromJS } from 'immutable'
import {
    FETCH_NEXT_VULNERABILITY_PAGE_START, FETCH_NEXT_VULNERABILITY_PAGE_SUCCESS,
    FETCH_VULNERABILITY_START,
    FETCH_VULNERABILITY_SUCCESS
} from "../constants/vulnerabilityConstant"

const initialState = fromJS({
  vulnerabilities: [],
  loading: false,
  next: null,
  previous: null,
  count: 0
})

export default function vulnerabilityReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_VULNERABILITY_START:
      return state
        .set('vulnerabilities', [])
        .set('loading', true)
        .set('next', null)
        .set('previous', null)
        .set('count', 0)
    case FETCH_VULNERABILITY_SUCCESS:
      return state
        .set('vulnerabilities', action.payload.results)
        .set('loading', false)
        .set('next', action.payload.next)
        .set('previous', action.payload.previous)
        .set('count', action.payload.count)
    case FETCH_NEXT_VULNERABILITY_PAGE_START:
      return state
        .set('vulnerabilities', [])
        .set('loading', true)
        .set('next', null)
        .set('previous', null)
        .set('count', 0)
    case FETCH_NEXT_VULNERABILITY_PAGE_SUCCESS:
      return state
        .set('vulnerabilities', action.payload.results)
        .set('loading', false)
        .set('next', action.payload.next)
        .set('previous', action.payload.previous)
        .set('count', action.payload.count)
    default:
      return state
  }
}
