import React from "react"
import { InfoCircleOutlined } from "@ant-design/icons"
import { Select, Switch, TreeSelect } from "antd"
import PropTypes from "prop-types"
import tool from "../../libs/tool"
import '../../assets/css/NucleiScanParameters.css'

const { SHOW_PARENT } = TreeSelect
const { Option } = Select


class NucleiScanParameters extends React.Component {
  state = {
    nucleiTemplates: [],
  }

  severities = ['info', 'low', 'medium', 'high', 'critical']

  componentDidMount() {
    tool.fetchNucleiTemplates().then((response) => {
      const treeData = []
      response.forEach((r) => {
        treeData.push({
          id: r.path,
          pId: r.id,
          value: r.path,
          title: r.name,
          isLeaf: !r.directory,
        })
      })

      this.setState({ nucleiTemplates: treeData })
    })
  }

  onLoadData = ({ id }) => {
    return tool.fetchNucleiTemplates(id).then((response) => {
      const templates = []
      response.forEach((r) => {
        templates.push({
            id: r.path,
            pId: id,
            value: r.path,
            title: r.name,
            isLeaf: !r.directory,
          })
      })

      this.setState({ nucleiTemplates: this.state.nucleiTemplates.concat(templates) })
    })
  }

  render() {
    return (
      <div>
        <div className="modal-sub-div">
          <b>Select templates</b><br/>
          <div><InfoCircleOutlined /> <span>If you do not select any template, will be scanned with all templates.</span></div>
          <TreeSelect
            treeDataSimpleMode
            style={{ width: '100%' }}
            value={this.props.scanState.templates}
            treeCheckable={true}
            showCheckedStrategy={SHOW_PARENT}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            placeholder="Please select"
            onChange={this.props.onNucleiScanTemplateChange}
            loadData={this.onLoadData}
            treeData={this.state.nucleiTemplates}
          />
        </div>

        <div className='modal-sub-div'>
          <b>Select severity</b><br/>
          <div><InfoCircleOutlined /> <span>If you do not select any severity, all severity will be scanned.</span></div>
          <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder="Please select"
            defaultValue={this.props.scanState.severities}
            onChange={this.props.onNucleiScanSeverityChange}
          >
            {
            this.severities.map((severity, index) => {
              return (
                  <Option key={index} value={severity}>{severity.charAt(0).toUpperCase() + severity.slice(1)}</Option>
              )
            })
          }
          </Select>
        </div>

        <div className="modal-sub-div">
          Include all URLs is <Switch checked={this.props.scanState.url.include} onChange={this.props.onNucleiScanIncludeURLChange} />
        </div>

        {this.props.scanState.url.include ? (
          <div className="modal-sub-div">
            Include all directories is <Switch checked={this.props.scanState.url.directory} onChange={this.props.onNucleiScanIncludeDirectoryChange} />
          </div>
        ) : null}
      </div>
    )
  }
}

NucleiScanParameters.propTypes = {
  scanState: PropTypes.any,
  onNucleiScanTemplateChange: PropTypes.func,
  onNucleiScanSeverityChange: PropTypes.func,
  onNucleiScanIncludeURLChange: PropTypes.func,
  onNucleiScanIncludeDirectoryChange: PropTypes.func,
}

export default NucleiScanParameters
