import { takeEvery, call, put, all } from 'redux-saga/effects'
import user from "../libs/user"
import { FETCH_USER_START, FETCH_USER_SUCCESS, FETCH_USER_ERROR } from "../constants/userConstant"
import { LOGIN_ERROR, LOGIN_START, LOGIN_SUCCESS } from "../constants/userConstant"
import { notification } from "antd"


export function* loginStart(data) {
  try {
      const response = yield call(user.login, data.data)

      yield put({
        type: LOGIN_SUCCESS,
        payload: response,
      })
    } catch (error) {
      if (error.response.status === 400) {
        notification['error']({
          message: 'Wrong Credentials',
        })
      } else {
        notification['error']({
          message: 'Unable to login',
        })
      }

      yield put({
        type: LOGIN_ERROR,
        error: error.message,
      })
    }
}

export function* fetchUser() {
  try {
      const response = yield call(user.fetch)

      const payload = {
          user: response
      }

      yield put({
        type: FETCH_USER_SUCCESS,
        payload: payload,
      })
    } catch (error) {
      yield put({ type: FETCH_USER_ERROR, error: error.message })
    }
}

export function* watchLoginStart() {
  yield takeEvery(LOGIN_START, loginStart)
}

export function* watchFetchUser() {
  yield takeEvery(FETCH_USER_START, fetchUser)
}

export default function* rootSaga() {
  yield all([
      watchLoginStart(),
      watchFetchUser(),
  ])
}
