import { takeEvery, call, put, all } from 'redux-saga/effects'
import { notification } from "antd"
import {
  FETCH_NEXT_SUBDOMAIN_PAGE_START,
  FETCH_NEXT_SUBDOMAIN_PAGE_SUCCESS,
  FETCH_SUBDOMAIN_START, FETCH_SUBDOMAIN_SUCCESS
} from "../constants/subdomainConstant"
import subdomain from "../libs/subdomain"


export function* fetchSubdomains(data) {
  try {
    const response = yield call(subdomain.fetch, data.params)

    yield put({
      type: FETCH_SUBDOMAIN_SUCCESS,
      payload: response,
    })
  } catch (error) {
    notification['error']({
      message: 'Unexpected error',
    })
  }
}

export function* fetchNextSubdomainPage(data) {
  try {
    const response = yield call(subdomain.nextPage, data.page, data.params)

    yield put({
      type: FETCH_NEXT_SUBDOMAIN_PAGE_SUCCESS,
      payload: response,
    })
  } catch (error) {
    notification['error']({
      message: 'Unexpected error',
    })
  }
}

export function* watchFetchSubdomains() {
  yield takeEvery(FETCH_SUBDOMAIN_START, fetchSubdomains)
}

export function* watchFetchNextSubdomainPage() {
  yield takeEvery(FETCH_NEXT_SUBDOMAIN_PAGE_START, fetchNextSubdomainPage)
}

export default function* rootSaga() {
  yield all([
      watchFetchSubdomains(),
      watchFetchNextSubdomainPage(),
  ])
}
