/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react"

// reactstrap components
import { Container, Row, Col, Button } from "reactstrap"
import { Modal, Select } from "antd"
import PropTypes from "prop-types"
import { createStructuredSelector } from "reselect"
import makeSelectProgram from "../../selectors/programSelector"
import { connect } from "react-redux"
import { createDomain } from "../../actions/domainActions"
import NewDomainView from "../../views/examples/NewDomainView";

const { Option } = Select

class HeaderDomains extends React.Component {
  state = {
    addingDomainModalShown: false,
    newDomain: {
      program: null,
      url: null,
      full_scope: true,
      bounty: true
    },
    visibilityOfCreateButton: false,
  }

  onNewDomainProgramChanged = (selected) => {
    this.setState({
      newDomain: {
        ...this.state.newDomain,
        program: selected,
      },
    })
  }

  onNewDomainUrlChanged = (e) => {
    this.setState({
      newDomain: {
        ...this.state.newDomain,
        url: e.target.value,
      },
    })
  }

  onNewDomainBountyChange = (e) => {
    this.setState({
      newDomain: {
        ...this.state.newDomain,
       bounty: e.target.checked,
      }
    })
  }

  onNewDomainFullScopeChange = (e) => {
    this.setState({
      newDomain: {
        ...this.state.newDomain,
       full_scope: e.target.checked,
      }
    })
  }

  handleAddNewDomain = () => {
    this.props.dispatch(createDomain(this.state.newDomain.program, this.state.newDomain))

    this.setState({
      addingDomainModalShown: false,
      newDomain: {
        program: null,
        url: null,
        full_scope: true,
        bounty: true
      }
    })
  }

  domainAddingModalToggle = () => {
    this.setState({
      addingDomainModalShown: !this.state.addingDomainModalShown,
    })
  }

  changeVisibilityOfCreateButton = (visibility) => {
    this.setState({
      visibilityOfCreateButton: visibility,
    })
  }

  render() {
    return (
      <>
        <Modal
          title='Add A New Domain'
          visible={this.state.addingDomainModalShown}
          onOk={this.handleAddNewDomain}
          onCancel={() => this.domainAddingModalToggle()}
          okText='Create'
          okButtonProps={{
            disabled: !this.state.visibilityOfCreateButton || !this.state.newDomain.program || !this.state.newDomain.url,
          }}
        >
          <div>
            <div className='modal-sub-div'>
              <span>Program</span><br/>
              <Select
                showSearch
                style={{ width: '100%' }}
                optionFilterProp='children'
                onChange={this.onNewDomainProgramChanged}
                defaultValue={this.state.newDomain ? this.state.newDomain.program : null}
                value={this.state.newDomain ? this.state.newDomain.program : null}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {
                  this.props.programs.programs.map((program, index) => {
                    return (
                        <Option key={index} value={program.uuid}>{program.name}</Option>
                    )
                  })
                }
              </Select>
            </div>
            <NewDomainView
              newDomain={this.state.newDomain}
              onNewDomainUrlChanged={this.onNewDomainUrlChanged}
              onNewDomainBountyChange={this.onNewDomainBountyChange}
              onNewDomainFullScopeChange={this.onNewDomainFullScopeChange}
              changeVisibilityOfCreateButton={this.changeVisibilityOfCreateButton}
            />
          </div>
        </Modal>

        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row>
                <Col lg="24" xl="12">
                  <Button color="success" size="lg" type="button" onClick={this.domainAddingModalToggle}>
                    + Add domain
                  </Button>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    )
  }
}

HeaderDomains.propTypes = {
  dispatch: PropTypes.func,
  programs: PropTypes.any,
}

const mapStateToProps = createStructuredSelector({
  programs: makeSelectProgram(),
})

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderDomains)
