import {
    FETCH_TOOL_START, FETCH_NUCLEI_TEMPLATES, FETCH_FFUF_WORDLIST
} from "../constants/toolConstant"

export function fetchTools(toolType) {
    return {
        type: FETCH_TOOL_START,
        toolType,
    }
}

export function fetchNucleiTemplates() {
    return {
        type: FETCH_NUCLEI_TEMPLATES,
    }
}

export function fetchFfufWordlist() {
    return {
        type: FETCH_FFUF_WORDLIST,
    }
}
