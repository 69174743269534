/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react"
import { Modal } from 'antd'

import '../../assets/css/HeaderPrograms.css'

// reactstrap components
import { Container, Row, Col, Button } from "reactstrap"
import NewProgramView from "../../views/examples/NewProgramView"


class HeaderPrograms extends React.Component {
  state = {
    addingProgramModalShown: false,
    newProgram: {
      name: null,
      briefUrl: null,
      platform: null
    },
  }

  addingProgramModalToggle = () => {
    this.setState({
      addingProgramModalShown: !this.state.addingProgramModalShown,
    })
  }

  onNewProgramNameChanged = (e) => {
    this.setState({
      newProgram: {
        ...this.state.newProgram,
        name: e.target.value,
      },
    })
  }

  onNewProgramBriefChanged = (e) => {
    this.setState({
      newProgram: {
        ...this.state.newProgram,
        briefUrl: e.target.value,
      },
    })
  }

  onPlatformChanged = (selected) => {
    this.setState({
      newProgram: {
        ...this.state.newProgram,
        platform: selected,
      },
    })
  }

  handleAddNewProgram = () => {
    const data = {
      name: this.state.newProgram.name,
      platform: this.state.newProgram.platform,
      brief_url: this.state.newProgram.briefUrl
    }

    this.props.handleAddNewProgram(data)

    this.setState({
      addingProgramModalShown: false,
      newProgram: {
        name: null,
        briefUrl: null,
        platform: null
      },
    })
  }

  render() {
    return (
      <>
        <Modal
            title='Add A New Program'
            visible={this.state.addingProgramModalShown}
            onOk={this.handleAddNewProgram}
            onCancel={() => this.addingProgramModalToggle()}
            okText='Create'
            okButtonProps={{
              disabled: !this.state.newProgram.name || !this.state.newProgram.briefUrl || !this.state.newProgram.platform,
            }}
        >
          <NewProgramView
            newProgram={this.state.newProgram}
            onNewProgramNameChanged={this.onNewProgramNameChanged}
            onNewProgramBriefChanged={this.onNewProgramBriefChanged}
            onProgramPlatformChanged={this.onPlatformChanged}
          />
        </Modal>

        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row>
                <Col lg="24" xl="12">
                  <Button color="success" size="lg" type="button" onClick={this.addingProgramModalToggle}>
                    + Add program
                  </Button>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    )
  }
}

export default HeaderPrograms
