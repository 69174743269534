/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react"
import { Table, Button, Space, Select, Modal, Input as AntInput } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import moment from 'moment'

import 'antd/dist/antd.css'

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
} from "reactstrap"

// core components
import HeaderPrograms from "components/Headers/HeaderPrograms.js"
import PropTypes from "prop-types"
import { createStructuredSelector } from "reselect"
import { connect } from "react-redux"
import makeSelectProgram from "../../selectors/programSelector"
import { updateProgram, createProgram, fetchPrograms, fetchNextProgramPage } from "../../actions/programAction"
import addSortedInfo from "../../utils/addSortParams"
import { StyledInput } from "../../css/subdomain.style"

const { Option } = Select


class Programs extends React.Component {

  state = {
    sortedInfo: null,
    editingProgram: null,
    editingProgramModalShown: false,
    pagination: {
      current: 1,
      total: 0,
      pageSize: 11
    },
    searchValue: null,
  }

  componentDidMount() {
    if (this.state.pagination.current > 1) {
        this.props.dispatch(fetchNextProgramPage(this.state.pagination.current))
    } else {
      this.props.dispatch(fetchPrograms())
    }
  }

  onSearchInputChanged = (e) => {
    const searchValue = e.target.value

    this.setState({
      searchValue,
    })
  }

  onSearchInputConfirmed = () => {
    const searchValue = this.state.searchValue

    if (searchValue) {
      let filters = `search=${searchValue}`
      filters = addSortedInfo(filters, this.state.sortedInfo)

      this.props.dispatch(fetchPrograms(filters))
    }
  }

  onEditIconClick = (editingUuid) => {
    const editingProgram = this.props.programs.programs.filter((p) => p.uuid === editingUuid)

    this.setState({
      editingProgram: editingProgram[0],
      editingProgramModalShown: true,
    })
  }

  onEditingProgramNameChanged = (e) => {
    this.setState({
      editingProgram: {
        ...this.state.editingProgram,
        name: e.target.value,
      },
    })
  }

  onEditingProgramBriefChanged = (e) => {
    this.setState({
      editingProgram: {
        ...this.state.editingProgram,
        brief_url: e.target.value,
      },
    })
  }

  onEditingProgramPlatformChanged = (selected) => {
    this.setState({
      editingProgram: {
        ...this.state.editingProgram,
        platform: selected,
      },
    })
  }

  handleChange = (pagination, filters, sorter) => {
    if (pagination.current > Math.round(this.state.pagination.count / 10)) return

    this.setState({
      sortedInfo: sorter,
      pagination: {
        ...this.state.pagination,
        current: pagination.current
      },
    }, () => {
      let filters = this.state.searchValue ? `search=${this.state.searchValue}` : ''
      filters = addSortedInfo(filters, this.state.sortedInfo)

      this.props.dispatch(fetchNextProgramPage(pagination.current, filters))
    })
  }

  handleUpdateProgram = () => {
    const data = {
      name: this.state.editingProgram.name,
      brief_url: this.state.editingProgram.brief_url,
      platform: this.state.editingProgram.platform
    }

    const allPrograms = this.props.programs.programs
    const editingProgram = allPrograms.find((p) => p.uuid === this.state.editingProgram.uuid)
    if (!editingProgram) return

    editingProgram.name = this.state.editingProgram.name
    editingProgram.brief_url = this.state.editingProgram.brief_url
    editingProgram.platform = this.state.editingProgram.platform

    this.props.dispatch(updateProgram(this.state.editingProgram.uuid, data))

    this.setState({
      editingProgram: null,
      editingProgramModalShown: false,
    })
  }

  handleAddNewProgram = (data) => {
    this.props.dispatch(createProgram(data))
  }

  clearFilters = () => {
    if (this.props.location.search) {
      window.location.href = '/admin/programs'
    }

    this.setState({
      sortedInfo: null,
      pagination: {
        current: 1,
        total: 0,
      },
    }, () => { this.props.dispatch(fetchPrograms()) })
  }

  clearAll = () => {
    this.setState({
      sortedInfo: null,
      pagination: {
        current: 1,
        total: 0,
      },
    }, () => { this.props.dispatch(fetchPrograms()) })
  }

  editingProgramModalClose = () => {
    this.setState({
      editingProgram: false,
      editingProgramModalShown: false,
    })
  }

  render() {
    let { sortedInfo } = this.state
    sortedInfo = sortedInfo || {}

    const { pagination } = this.state
    if (this.props.programs.count !== pagination.total) pagination.total = this.props.programs.count

    const columns = [
      {
        title: 'Program',
        dataIndex: 'name',
        key: 'name',
        ellipsis: true,
        editable: true,
        render: (text) => <b>{text}</b>,
      },
      {
        title: 'Brief Link',
        dataIndex: 'brief_url',
        key: 'brief_url',
        ellipsis: true,
        editable: true,
        width: 400,
        render: (text) => <a href={`${text}`}>{text}</a>,
      },
      {
        title: 'Domains / Subdomains',
        key: 'count',
        dataIndex: 'count',
        render (text) { return(
          <>
            <a href={`/admin/domains?program=${text.program.uuid}`}>{text.domain}</a> / <a href={`/admin/subdomains?program=${text.program.name}`}>{text.subdomain}</a>
          </>
        )}
      },
      {
        title: 'Vulnerabilities',
        dataIndex: 'count',
        key: 'vulnerabilities',
        sorter: (a, b) => a.count.vulnerability - b.count.vulnerability,
        sortOrder: sortedInfo.columnKey === 'vulnerabilities' && sortedInfo.order,
        ellipsis: true,
        render: (text) => <a href={`/admin/bugs/?program=${text.program.name}`}>{text.vulnerability}</a>,
      },
      {
        title: 'Bounty',
        dataIndex: 'bounty',
        key: 'bounty',
        sorter: (a, b) => a.bounty - b.bounty,
        sortOrder: sortedInfo.columnKey === 'bounty' && sortedInfo.order,
        ellipsis: true,
      },
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        sorter: (a, b) => moment(a.date) - moment(b.date),
        sortOrder: sortedInfo.columnKey === 'date' && sortedInfo.order,
        ellipsis: true,
        render: (text) => moment(text).format('YYYY/MM/DD - HH:mm')
      },
      {
        title: 'Action',
        dataIndex: 'uuid',
        key: 'edit',
        ellipsis: true,
        render: (text) => <div style={{ cursor: 'pointer' }} onClick={() => this.onEditIconClick(text)}><EditOutlined /></div>
      },
    ]

    return (
      <>
        <Modal
          title='Update Program'
          visible={this.state.editingProgramModalShown}
          onOk={this.handleUpdateProgram}
          onCancel={() => this.editingProgramModalClose()}
          okText='Update'
          okButtonProps={{
            disabled: this.state.editingProgram && (
                !this.state.editingProgram.name || !this.state.editingProgram.brief_url || !this.state.editingProgram.platform),
          }}
        >
          <div>
            <div className='modal-sub-div'>
              <span>Name</span>
              <AntInput
                value={this.state.editingProgram ? this.state.editingProgram.name : null}
                onChange={this.onEditingProgramNameChanged}
              />
            </div>
            <div className='modal-sub-div'>
              <span>Brief URL</span>
              <AntInput
                value={this.state.editingProgram ? this.state.editingProgram.brief_url : null}
                onChange={this.onEditingProgramBriefChanged}
              />
            </div>
            <div className='modal-sub-div'>
              <span>Platform</span><br/>
              <Select
                showSearch
                style={{ width: 200 }}
                optionFilterProp='children'
                onChange={this.onEditingProgramPlatformChanged}
                defaultValue={this.state.editingProgram ? this.state.editingProgram.platform : null}
                value={this.state.editingProgram ? this.state.editingProgram.platform : null}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value='bugcrowd'>Bugcrowd</Option>
                <Option value='hackerone'>Hackerone</Option>
                <Option value='synack'>Synack</Option>
              </Select>
            </div>
          </div>
        </Modal>

        <HeaderPrograms handleAddNewProgram={this.handleAddNewProgram} />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">Programs table</h3>
                  <br/>
                  <Row>
                    <Col lg="24" xl="12">
                      <StyledInput
                        type="text"
                        placeholder="Search"
                        value={this.state.searchValue}
                        onChange={this.onSearchInputChanged}
                        onBlur={this.onSearchInputConfirmed}
                        onPressEnter={this.onSearchInputConfirmed}
                      />
                    </Col>
                  </Row>
                </CardHeader>
                <Space style={{ marginBottom: 16, marginLeft: 24 }}>
                  <Button onClick={this.clearFilters}>Clear filters</Button>
                  <Button onClick={this.clearAll}>Clear filters and sorters</Button>
                </Space>
                <Table
                  columns={columns}
                  pagination={pagination}
                  loading={this.props.programs.loading}
                  dataSource={(() => {
                    const programs = this.props.programs.programs || []
                    return programs.map((program, index) => {
                      return {
                        uuid: program.uuid,
                        key: index,
                        name: program.name,
                        brief_url: program.brief_url,
                        date: program.created_at,
                        count: {
                          ...program.count,
                          program: {
                            uuid: program.uuid,
                            name: program.name,
                          }
                        },
                        bounty: program.bounty === true ? 'Present' : 'No',
                      }
                    })
                  })()}
                  onChange={this.handleChange}
                />
              </Card>
            </div>
          </Row>
        </Container>
      </>
    )
  }
}

Programs.propTypes = {
  dispatch: PropTypes.func,
  programs: PropTypes.any,
}

const mapStateToProps = createStructuredSelector({
  programs: makeSelectProgram(),
})

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Programs)
