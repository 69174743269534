import request from 'utils/request'

const user = {
  login(data) {
    const { username, password } = data;

    return request.post('/jwt', { username: username, password })
      .then((r) => r.data)
      .then((response) => {
        // Save token to local storage
        localStorage.token = response.token

        return response
      })
  },
  fetch() {
    return request.get('/me')
      .then((r) => r.data)
  },
}

export default user
