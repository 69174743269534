import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Input, Select } from "antd"

const { Option } = Select


class NewProgramView extends React.Component {
  render() {
    return (
      <div style={this.props.style}>
        <div className='modal-sub-div'>
          <span>Name</span>
          <Input
            placeholder='test'
            value={this.props.newProgram.name}
            onChange={this.props.onNewProgramNameChanged}
          />
        </div>
        <div className='modal-sub-div'>
          <span>Brief URL</span>
          <Input
            placeholder='https://platform.com/test'
            value={this.props.newProgram.briefUrl}
            onChange={this.props.onNewProgramBriefChanged}
          />
        </div>
        <div className='modal-sub-div'>
          <span>Platform</span><br/>
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder='Select a platform'
            optionFilterProp='children'
            onChange={this.props.onProgramPlatformChanged}
            value={this.props.newProgram.platform}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value='bugcrowd'>Bugcrowd</Option>
            <Option value='hackerone'>Hackerone</Option>
            <Option value='synack'>Synack</Option>
          </Select>
        </div>
      </div>
    )
  }
}

NewProgramView.propTypes = {
  dispatch: PropTypes.func,
  newProgram: PropTypes.any,
  onNewProgramNameChanged: PropTypes.func,
  onNewProgramBriefChanged: PropTypes.func,
  onProgramPlatformChanged: PropTypes.func,
  style: PropTypes.any,
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  }
}

export default connect(null, mapDispatchToProps)(NewProgramView)
