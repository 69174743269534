import React from "react"

// reactstrap components
import {Container, Row, Col, Button} from "reactstrap"
import {
  Modal, Select, Switch, Upload, message
} from "antd"
import PropTypes from "prop-types"
import { createStructuredSelector } from "reselect"
import makeSelectProgram from "../../selectors/programSelector"
import { connect } from "react-redux"
import { fetchFilteredDomains } from "../../actions/domainActions"
import makeSelectDomain from "../../selectors/domainSelector"
import { InfoCircleOutlined } from "@ant-design/icons"
import { InboxOutlined } from '@ant-design/icons'
import makeSelectTool from "../../selectors/toolSelector"
import {
  fetchFfufWordlist,
  fetchTools
} from "../../actions/toolActions"
import tool from "../../libs/tool"
import { startNucleiScanToAllSubdomains } from "../../actions/scanActions"
import NucleiScanParameters from "../../views/examples/NucleiScanParameters"
import ProgramSelection from "../../views/examples/ProgramSelection"

const { Option } = Select
const { Dragger } = Upload

class HeaderScans extends React.Component {
  state = {
    newScanAddModalShown: false,
    newScan: {
      type: null,
      programs: [],
      domains: [],
      tools: [],
      dynamic: {
        scan: true,
        read: false,
      },
      url: {
        include: false,
        directory: false,
      },
      templates: [],
      severities: [],
      wordlist: null,
    },
    newNucleiTemplateAddModalShown: false,
    newNucleiTemplates: [],
    scanWithNewTemplates: false,
    allProgramsChecked: false,
  }

  dragAndDropProps = {
    name: 'file',
    multiple: true,
    action: `${process.env.REACT_APP_API_URL}/templates/nuclei/new`,
    headers: {'Authorization': localStorage.getItem('token') ? `JWT ${localStorage.getItem('token')}` : ''},
    beforeUpload: file => {
      if (file.type !== 'application/x-yaml') {
        message.error(`${file.name} is not a yaml file`);
      }
      return file.type === 'application/x-yaml' ? true : Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      const { status } = info.file

      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`)
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      } else if (status === 'removed') {
        message.success(`${info.file.name} file removed successfully.`)
      }

      this.setState({
        newNucleiTemplates: info.fileList,
      })
    },
    onRemove: (e) => {
      tool.removeNewNucleiTemplate(e)
        .then((response) => this.setState({ newNucleiTemplates: e.fileList || [] }))
        .catch((error) => {
          console.error('Error:', error)
        })
    },
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: percent => `${parseFloat(percent.toFixed(2))}%`,
    },
  }

  handleAddNewScan = () => {
    const data = this.state.newScan
    if (this.state.allProgramsChecked) {
      data.programs = []
    }

    this.props.handleAddNewScan(data)
    this.newScanAddingModalToggle()
  }

  onNewScanTypeChange = (selected) => {
    if (selected === 'subdomain') this.props.dispatch(fetchTools(selected))
    if (selected === 'ffuf') this.props.dispatch(fetchFfufWordlist())

    let newScan = this.state.newScan
    if (this.state.newScan.type !== null && this.state.newScan.type !== selected) {
      newScan = {
        type: selected,
        programs: [],
        domains: [],
        tools: [],
        dynamic: {
          scan: true,
          read: false,
        },
        url: {
          include: false,
          directory: false,
        },
        templates: [],
        severities: [],
      }
    } else { newScan.type = selected }

    this.setState({
      newScan: newScan,
    })
  }

  onNewScanProgramChange = (selectedList) => {
    if (selectedList.length) this.props.dispatch(fetchFilteredDomains(selectedList))

    const allProgramsCount = this.props.programs.programs.length

    this.setState({
      newScan: {
        ...this.state.newScan,
        programs: selectedList,
      },
      allProgramsChecked: selectedList.length === allProgramsCount
    })
  }

  onNewScanDomainsChange = (selectedList) => {
    this.setState({
      newScan: {
        ...this.state.newScan,
        domains: selectedList,
      },
    })
  }

  onNewScanToolsChange = (selectedList) => {
    this.setState({
      newScan: {
        ...this.state.newScan,
        tools: selectedList,
      },
    })
  }

  onNewScanDynamicChange = (value) => {
    this.setState({
      newScan: {
        ...this.state.newScan,
        dynamic: {
          ...this.state.newScan.dynamic,
          scan: value
        },
      },
    })
  }

  onNewScanIncludeURLChange = (value) => {
    this.setState({
      newScan: {
        ...this.state.newScan,
        url: {
          ...this.state.newScan.url,
          include: value,
        },
      },
    })
  }

  onNewScanIncludeDirectoryChange = (value) => {
    this.setState({
      newScan: {
        ...this.state.newScan,
        url: {
          ...this.state.newScan.url,
          directory: value,
        },
      },
    })
  }

  onNewScanNucleiTemplateChange = (value) => {
    this.setState({
      newScan: {
        ...this.state.newScan,
        templates: value,
      },
    })
  }

  onNewScanSeverityChange = (selectedList) => {
    this.setState({
      newScan: {
        ...this.state.newScan,
        severities: selectedList,
      },
    })
  }

  onNewScanWordlistChange = (selected) => {
    this.setState({
      newScan: {
        ...this.state.newScan,
        wordlist: selected,
      },
    })
  }

  onNewNucleiTemplateAddingModalToggle = () => {
    if (this.state.newNucleiTemplateAddModalShown) {
      if (!this.state.newNucleiTemplates.length) {
        this.state.newNucleiTemplates.forEach(file => tool.removeNewNucleiTemplate(file))
      } else if (this.state.scanWithNewTemplates) {
        const data = {
          templates: this.state.newNucleiTemplates.map((t) => t.name)
        }
        this.props.dispatch(startNucleiScanToAllSubdomains(data))
      }
    }

    this.setState({
      newNucleiTemplateAddModalShown: !this.state.newNucleiTemplateAddModalShown,
      newNucleiTemplates: [],
      scanWithNewTemplates: false,
    })
  }

  onScanWithNewTemplatesChange = (value) => {
    this.setState({
      scanWithNewTemplates: value,
    })
  }

  onNewScanSelectAllProgramChange = (e) => {
    const checked = e.target.checked

    if (checked === true) {
      const allProgramUuidList = this.props.programs.programs.map((p) => p.uuid)

      if (allProgramUuidList.length) this.props.dispatch(fetchFilteredDomains(allProgramUuidList))

      this.setState({
        newScan: {
          ...this.state.newScan,
          programs: allProgramUuidList,
        },
        allProgramsChecked: true,
      })
    } else {
      this.setState({
        newScan: {
          ...this.state.newScan,
          programs: [],
        },
        allProgramsChecked: false,
      })
    }
  }

  onNewDynamicReadChange = (value) => {
    this.setState({
      newScan: {
        ...this.state.newScan,
        dynamic: {
          ...this.state.newScan.dynamic,
          read: value
        },
      },
    })
  }

  newScanAddingModalToggle = () => {
    this.setState({
      newScanAddModalShown: !this.state.newScanAddModalShown,
      newScan: {
        type: null,
        programs: [],
        domains: [],
        tools: [],
        dynamic: {
          scan: true,
          read: false,
        },
        url: {
          include: false,
          directory: false,
        },
        templates: [],
        severities: [],
      },
      allProgramsChecked: false,
    })
  }

  render() {
    return (
      <>
        <Modal
          title='Start A New Scan'
          visible={this.state.newScanAddModalShown}
          onOk={this.handleAddNewScan}
          onCancel={() => this.newScanAddingModalToggle()}
          okText='Start'
          okButtonProps={{
            disabled: !this.state.newScan.type || !this.state.newScan.programs.length ||
                (this.state.newScan.type === 'ffuf' && !this.state.newScan.wordlist),
          }}
        >
          <div>
            <div className='modal-sub-div'>
              <b>Select a scan type</b><br/>
              <Select
                showSearch
                style={{ width: '100%' }}
                optionFilterProp='children'
                onChange={this.onNewScanTypeChange}
                defaultValue={this.state.newScan ? this.state.newScan.type : null}
                value={this.state.newScan ? this.state.newScan.type : null}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option key='subdomain' value='subdomain'>Subdomain</Option>
                <Option key='nuclei' value='nuclei'>Nuclei</Option>
                <Option key='ffuf' value='ffuf'>FFUF</Option>
              </Select>
            </div>

            {this.state.newScan.type === 'subdomain' ? (
              <div className='modal-sub-div'>
                <b>Select tools</b><br/>
                <div style={{ marginBottom: '4px' }}><InfoCircleOutlined /> <span>If you do not select any tool, will be scanned with all tools.</span></div>
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select"
                  defaultValue={this.state.newScan.tools}
                  onChange={this.onNewScanToolsChange}
                >
                  {
                  this.props.tools.tools.map((tool, index) => {
                    return (
                        <Option key={index} value={tool.uuid}>{tool.name}</Option>
                    )
                  })
                }
                </Select>
              </div>
            ) : null}

            {this.state.newScan.type !== null ? (
              <div>
                <ProgramSelection
                  programs={this.props.programs.programs}
                  showSelectAllProgramsCheckbox={true}
                  allProgramsChecked={this.state.allProgramsChecked}
                  onNewScanSelectAllProgramChange={this.onNewScanSelectAllProgramChange}
                  onProgramSelectionChange={this.onNewScanProgramChange}
                  selectedPrograms={this.state.newScan.programs}
                  mode={'multiple'}
                />

                {this.state.newScan.programs.length ? (
                  <div className='modal-sub-div'>
                    <b>Select domains</b><br/>
                    <div><InfoCircleOutlined /> <span>If you do not select any domain, all domains belonging to the program are scanned.</span></div>
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: '100%' }}
                      placeholder="Please select"
                      defaultValue={this.state.newScan.domains}
                      onChange={this.onNewScanDomainsChange}
                    >
                      {
                      this.props.domains.domains.map((domain, index) => {
                        return (
                            <Option key={index} value={domain.uuid}>{domain.url}</Option>
                        )
                      })
                    }
                    </Select>
                  </div>
                ) : null}
              </div>
            ) : null}

            {this.state.newScan.type === 'nuclei' ? (
              <NucleiScanParameters
                scanState={this.state.newScan}
                onNucleiScanTemplateChange={this.onNewScanNucleiTemplateChange}
                onNucleiScanSeverityChange={this.onNewScanSeverityChange}
                onNucleiScanIncludeURLChange={this.onNewScanIncludeURLChange}
                onNucleiScanIncludeDirectoryChange={this.onNewScanIncludeDirectoryChange}
              />
            ) : null}

            {this.state.newScan.type === 'ffuf' ? (
              <div className='modal-sub-div'>
                <b>Select a wordlist</b><br/>
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  optionFilterProp='children'
                  onChange={this.onNewScanWordlistChange}
                  defaultValue={this.state.newScan ? this.state.newScan.wordlist : null}
                  value={this.state.newScan ? this.state.newScan.wordlist : null}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    this.props.tools.ffufWordlist.map((wordlist, index) => {
                      return (
                          <Option key={index} value={wordlist}>{wordlist}</Option>
                      )
                    })
                  }
                </Select>
              </div>
            ): null}

            <div className="modal-sub-div">
              Dynamic scan is <Switch checked={this.state.newScan.dynamic.scan} onChange={this.onNewScanDynamicChange} />
            </div>
            <div className="modal-sub-div">
              Dynamic read is <Switch checked={this.state.newScan.dynamic.read} onChange={this.onNewDynamicReadChange} />
            </div>
          </div>
        </Modal>

        <Modal
          title='Upload New Nuclei Template'
          visible={this.state.newNucleiTemplateAddModalShown}
          onOk={() => this.onNewNucleiTemplateAddingModalToggle()}
          onCancel={() => this.onNewNucleiTemplateAddingModalToggle()}
          okText='Save'
          okButtonProps={{
            disabled: !this.state.newNucleiTemplates.length,
          }}
        >
          <div>
            <Dragger {...this.dragAndDropProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. You can upload only yaml files.
              </p>
            </Dragger>

            {this.state.newNucleiTemplates.length ? (
              <div style={{ marginTop: '16px' }}>
                <Switch checked={this.state.scanWithNewTemplates} onChange={this.onScanWithNewTemplatesChange} /> Scan all subdomains with uploaded templates
              </div>
            ) : null}
          </div>
        </Modal>

        <div className="header bg-gradient-info pb-8 pt-5 pt-md -8">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row style={{  marginTop: '16px' }}>
                <Col lg="24" xl="1.1">
                  <Button
                    color="success"
                    size="lg"
                    type="button"
                    onClick={this.newScanAddingModalToggle}
                    style={{ marginLeft: '14px' }}
                  >
                    + Start New Scan
                  </Button>
                </Col>
                <Col lg="24" xl="10">
                  <Button color="success" size="lg" type="button" onClick={this.onNewNucleiTemplateAddingModalToggle}>
                    + Upload New Nuclei Template
                  </Button>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    )
  }
}

HeaderScans.propTypes = {
  dispatch: PropTypes.func,
  programs: PropTypes.any,
  domains: PropTypes.any,
  tools: PropTypes.any,
  handleAddNewScan: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  programs: makeSelectProgram(),
  domains: makeSelectDomain(),
  tools: makeSelectTool(),
})

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderScans)
