import { fromJS } from 'immutable'
import {
    CREATE_DOMAIN_SUCCESS,
    FETCH_DOMAIN_START,
    FETCH_DOMAIN_SUCCESS,
    FETCH_FILTERED_DOMAINS_SUCCESS,
    FETCH_NEXT_DOMAIN_PAGE_START, FETCH_NEXT_DOMAIN_PAGE_SUCCESS,
    UPDATE_DOMAIN_SUCCESS
} from "../constants/domainConstant"

const initialState = fromJS({
  domains: [],
  loading: false,
  next: null,
  previous: null,
  count: 0
})

export default function domainReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DOMAIN_START:
      return state
        .set('domains', [])
        .set('loading', true)
        .set('next', null)
        .set('previous', null)
        .set('count', 0)
    case FETCH_DOMAIN_SUCCESS:
      return state
        .set('domains', action.payload.results)
        .set('loading', false)
        .set('next', action.payload.next)
        .set('previous', action.payload.previous)
        .set('count', action.payload.count)
    case UPDATE_DOMAIN_SUCCESS:
      return state
        .update('domains', (domains) => {
          const updatingDomainId = domains.findIndex((d) => d.uuid === action.payload.uuid)
          const newDomainList = domains.slice()

          newDomainList[updatingDomainId] = action.payload

          return newDomainList
        })
    case CREATE_DOMAIN_SUCCESS:
      return state
        .update('domains', (domains) => {
          return [...domains, action.payload].sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        })
    case FETCH_FILTERED_DOMAINS_SUCCESS:
      return state
        .set('domains', action.payload.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)))
    case FETCH_NEXT_DOMAIN_PAGE_START:
      return state
        .set('domains', [])
        .set('loading', true)
        .set('next', null)
        .set('previous', null)
        .set('count', 0)
    case FETCH_NEXT_DOMAIN_PAGE_SUCCESS:
      return state
        .set('domains', action.payload.results)
        .set('loading', false)
        .set('next', action.payload.next)
        .set('previous', action.payload.previous)
        .set('count', action.payload.count)
    default:
      return state
  }
}
