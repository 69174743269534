import {
  FETCH_NEXT_SUBDOMAIN_PAGE_START,
  FETCH_SUBDOMAIN_START
} from "../constants/subdomainConstant"


export function fetchSubdomains(params) {
  return {
    type: FETCH_SUBDOMAIN_START,
    params
  }
}

export function fetchNextSubdomainPage(page, params) {
  return {
    type: FETCH_NEXT_SUBDOMAIN_PAGE_START,
    page,
    params,
  }
}
