import request from 'utils/request'

const program = {
  fetch(params = null) {
    let url = '/programs'
    if (params) url += `?${params}`

    return request
      .get(url)
      .then((r) => r.data)
  },
  nextPage(page, params) {
    let url = `programs?page=${page}`
    if (params) url += `&${params}`

    return request
      .get(url)
      .then((r) => r.data)
  },
  create(data) {
    return request
      .post('programs', data)
      .then((r) => r.data)
  },
  update(programUuid, data) {
    return request
      .patch(`programs/${programUuid}`, data)
      .then((r) => r.data)
  },
}

export default program
