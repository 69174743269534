import { fromJS } from "immutable"
import {
  CREATE_SUBMISSION_SUCCESS,
  FETCH_ISSUE_START,
  FETCH_ISSUE_SUCCESS, FETCH_NEXT_SUBMISSION_PAGE_START, FETCH_NEXT_SUBMISSION_PAGE_SUCCESS,
  FETCH_STEP_START,
  FETCH_STEP_SUCCESS, FETCH_SUBMISSIONS_START, FETCH_SUBMISSIONS_SUCCESS, UPDATE_SUBMISSION_SUCCESS
} from "../constants/submissionConstant"

const initialState = fromJS({
  submissions: [],
  next: null,
  previous: null,
  count: 0,
  loading: false,
  issues: [],
  step: {
    content: '',
    params: [],
  },
})

export default function submissionReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ISSUE_START:
      return state
        .set('issues', [])
    case FETCH_ISSUE_SUCCESS:
      return state
        .set('issues', action.payload)
    case FETCH_STEP_START:
      return state
        .setIn(['step', 'content'], '')
        .setIn(['step', 'params'], [])
    case FETCH_STEP_SUCCESS:
      return state
        .setIn(['step', 'content'], action.payload.step.content)
        .setIn(['step', 'params'], action.payload.step.params)
    case CREATE_SUBMISSION_SUCCESS:
      return state
        .update('submissions', (submissions) => {
          return [...submissions, action.payload].sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        })
    case FETCH_SUBMISSIONS_START:
      return state
        .set('submissions', [])
        .set('loading', true)
        .set('next', null)
        .set('previous', null)
        .set('count', 0)
    case FETCH_SUBMISSIONS_SUCCESS:
      return state
        .set('submissions', action.payload.results)
        .set('loading', false)
        .set('next', action.payload.next)
        .set('previous', action.payload.previous)
        .set('count', action.payload.count)
    case FETCH_NEXT_SUBMISSION_PAGE_START:
      return state
        .set('submissions', [])
        .set('loading', true)
        .set('next', null)
        .set('previous', null)
        .set('count', 0)
    case FETCH_NEXT_SUBMISSION_PAGE_SUCCESS:
      return state
        .set('submissions', action.payload.results)
        .set('loading', false)
        .set('next', action.payload.next)
        .set('previous', action.payload.previous)
        .set('count', action.payload.count)
    case UPDATE_SUBMISSION_SUCCESS:
      return state
        .update('submissions', (submissions) => {
          const newSubmissions = submissions.slice()

          const updatedSubmissionId = submissions.findIndex((p) => p.uuid === action.payload.uuid)
          newSubmissions[updatedSubmissionId] = action.payload

          return newSubmissions
        })
    default:
      return state
  }
}
