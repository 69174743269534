import prepareFilterData from "./filterData";
import addSortedInfo from "./addSortParams";

export default function getParams(tags, sortedInfo, location, filters) {
  let params = ''
  if (tags.length) {
    params = prepareFilterData(tags, sortedInfo)
  } else if (location.search) {
    params = location.search.replace('?', '')
  }

  params = addSortedInfo(params, sortedInfo)

  if (filters.bounty && filters.bounty.length === 1) {
    params += `${params.length ? '&' : ''}bounty=${filters.bounty[0]}`
  }

  if (filters.full_scope && filters.full_scope.length === 1) {
    params += `${params.length ? '&' : ''}full_scope=${filters.full_scope[0]}`
  }

  return params
}
