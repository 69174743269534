import {
  FETCH_NEXT_SCAN_PAGE_START,
  FETCH_SCAN_START,
  FFUF_SCAN_START,
  NUCLEI_SCAN_START,
  NUCLEI_SCAN_TO_ALL_SUBDOMAINS_START,
  SUBDOMAIN_SCAN_START
} from "../constants/scanConstant"


export function fetchScans(params) {
  return {
    type: FETCH_SCAN_START,
    params
  }
}

export function fetchNextScanPage(page, params) {
  return {
    type: FETCH_NEXT_SCAN_PAGE_START,
    page,
    params,
  }
}

export function startSubdomainScan(data) {
  return {
    type: SUBDOMAIN_SCAN_START,
    data,
  }
}

export function startNucleiScan(data) {
  return {
    type: NUCLEI_SCAN_START,
    data,
  }
}

export function startFfufScan(data) {
  return {
    type: FFUF_SCAN_START,
    data
  }
}

export function startNucleiScanToAllSubdomains(data) {
  return {
    type: NUCLEI_SCAN_TO_ALL_SUBDOMAINS_START,
    data,
  }
}
