import { all, call, put, takeEvery } from "redux-saga/effects"
import { notification } from "antd"
import {
    FETCH_NEXT_VULNERABILITY_PAGE_START,
    FETCH_NEXT_VULNERABILITY_PAGE_SUCCESS,
    FETCH_VULNERABILITY_START,
    FETCH_VULNERABILITY_SUCCESS
} from "../constants/vulnerabilityConstant"
import vulnerability from "../libs/vulnerability"


export function* fetchVulnerabilities(data) {
  try {
    const response = yield call(vulnerability.fetch, data.params)

    yield put({
      type: FETCH_VULNERABILITY_SUCCESS,
      payload: response,
    })
  } catch (error) {
    notification['error']({
      message: 'Unexpected error',
    })
  }
}

export function* fetchNextVulnerabilityPage(data) {
  try {
    const response = yield call(vulnerability.nextPage, data.page, data.params)

    yield put({
      type: FETCH_NEXT_VULNERABILITY_PAGE_SUCCESS,
      payload: response,
    })
  } catch (error) {
    notification['error']({
      message: 'Unexpected error',
    })
  }
}

export function* watchFetchVulnerabilities() {
  yield takeEvery(FETCH_VULNERABILITY_START, fetchVulnerabilities)
}

export function* watchFetchNextVulnerabilityPage() {
  yield takeEvery(FETCH_NEXT_VULNERABILITY_PAGE_START, fetchNextVulnerabilityPage)
}

export default function* rootSaga() {
  yield all([
      watchFetchVulnerabilities(),
      watchFetchNextVulnerabilityPage(),
  ])
}
