import { fromJS } from 'immutable'
import {
  LOGIN_SUCCESS,  LOGIN_ERROR,
  FETCH_USER_SUCCESS,  FETCH_USER_ERROR
} from "../constants/userConstant"

const initialState = fromJS({
  user: null,
  error: false,
  errorMessage: null,
})

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return state
        .set('user', action.payload.user)
        .set('error', false)
        .set('errorMessage', null)
    case LOGIN_ERROR:
      return state
        .set('user', null)
        .set('error', true)
        .set('errorMessage', action.error)
    case FETCH_USER_SUCCESS:
      return state
        .set('user', action.payload.user)
        .set('error', false)
        .set('errorMessage', null)
    case FETCH_USER_ERROR:
      return state
        .set('user', null)
        .set('error', true)
        .set('errorMessage', action.error)
    default:
      return state
  }
}
