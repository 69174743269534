import { fromJS } from 'immutable'
import {
  FETCH_NEXT_SCAN_PAGE_START,
  FETCH_NEXT_SCAN_PAGE_SUCCESS,
  FETCH_SCAN_START,
  FETCH_SCAN_SUCCESS
} from "../constants/scanConstant"

const initialState = fromJS({
  scans: [],
  loading: false,
  count: 0
})

export default function scanReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SCAN_START:
      return state
        .set('scans', [])
        .set('loading', true)
        .set('count', 0)
    case FETCH_SCAN_SUCCESS:
      return state
        .set('scans', action.payload.results)
        .set('loading', false)
        .set('count', action.payload.count)
    case FETCH_NEXT_SCAN_PAGE_START:
      return state
        .set('scans', [])
        .set('loading', true)
        .set('count', 0)
    case FETCH_NEXT_SCAN_PAGE_SUCCESS:
      return state
        .set('scans', action.payload.results)
        .set('loading', false)
        .set('count', action.payload.count)
    default:
      return state
  }
}
