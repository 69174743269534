import React from "react"
import HeaderScans from "../../components/Headers/HeaderScans"
import PropTypes from "prop-types"
import { createStructuredSelector } from "reselect"
import { connect } from "react-redux"
import {
    Card, CardHeader, Container, Row
} from "reactstrap"
import {
  Button, Space, Switch, Table, Tooltip
} from "antd"
import moment from "moment"
import makeSelectScan from "../../selectors/scanSelector"
import {
  fetchScans, fetchNextScanPage, startSubdomainScan, startNucleiScan, startFfufScan
} from "../../actions/scanActions"
import { PauseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'
import SearchFilter from "./SearchFilter"
import addSortedInfo from "../../utils/addSortParams"
import getParams from "../../utils/getParams"

class Scans extends React.Component {
  state = {
    sortedInfo: null,
    pagination: {
      current: 1,
      total: 0,
    },
    autoRefresh: true,
    tags: [],
  }

  componentDidMount() {
    let filters = this.props.location.search.replace('?', '')
    filters = addSortedInfo(filters, this.state.sortedInfo)

    if (this.state.pagination.current) {
        this.props.dispatch(fetchNextScanPage(this.state.pagination.current, filters))
      } else {
        this.props.dispatch(fetchScans(filters))
      }

    this.scanInterval()
  }

  componentWillUnmount() {
   clearInterval(this.interval)
 }

  handleTableChange = (pagination, filters, sorter) => {
    if (pagination.current > Math.round(this.state.pagination.count / 10)) return

    this.setState({
      sortedInfo: sorter,
      pagination: {
        ...this.state.pagination,
        current: pagination.current
      },
    }, () => {
      const params = getParams(this.state.tags, this.state.sortedInfo, this.props.location, filters)

      this.props.dispatch(fetchNextScanPage(pagination.current, params))
    })
  }

  handleAddNewScan = (data) => {
    const filters = this.props.location.search.replace('?', '')

    if (data.type === 'subdomain') {
      delete data.type
      this.props.dispatch(startSubdomainScan(data))
    } else if (data.type === 'nuclei') {
      delete data.type
      this.props.dispatch(startNucleiScan(data))
    } else if (data.type === 'ffuf') {
      delete data.type
      this.props.dispatch(startFfufScan(data))
    }

    this.props.dispatch(fetchScans(filters))
  }

  onCloseScanClick = () => {
    console.log('onCloseScanClick')
  }

  onAutoRefreshChange = (value) => {
    if (value === true) {
      this.scanInterval()
    } else {
      clearInterval(this.interval)
    }

    this.setState({
      autoRefresh: value,
    })
  }

  scanInterval = () => {
    this.interval = setInterval(() => {
      const params = getParams(this.state.tags, this.state.sortedInfo, this.props.location, {})

      if (this.state.pagination.current) {
        this.props.dispatch(fetchNextScanPage(this.state.pagination.current, params))
      } else {
        this.props.dispatch(fetchScans(params))
      }
    }, 5000)
  }

  setTags = (tags) => {
    this.setState({
      tags,
    })
  }

  clearAll = () => {
    if (this.props.location.search) {
      window.location.href = '/admin/scans'
    }

    this.setState({
      sortedInfo: null,
      pagination: {
        current: 1,
        total: 0,
      },
      tags: [],
    }, () => { this.props.dispatch(fetchScans()) })
  }

  render() {
    const { pagination } = this.state
    if (this.props.scans.count !== pagination.total) pagination.total = this.props.scans.count

    let { sortedInfo } = this.state
    sortedInfo = sortedInfo || {}
    const columns = [
      {
        title: 'Tool',
        dataIndex: 'tool',
        key: 'tool',
        ellipsis: true,
        render: (text) => <a href={`/admin/tools?tool=${text.uuid}`}>{text.name}</a>,
      },
      {
        title: 'Domains',
        dataIndex: 'domains',
        key: 'domains',
        ellipsis: true,
        render: (text) => text ?
            <Tooltip title={text.join(', ')}>
              <span>{text.join(', ')}</span>
            </Tooltip> : '',
        width: '500px'
      },
      {
        title: 'Subdomains',
        dataIndex: 'subdomains',
        key: 'subdomains',
        sorter: (a, b) => a.subdomains.count - b.subdomains.count,
        sortOrder: sortedInfo.columnKey === 'subdomains' && sortedInfo.order,
        ellipsis: true,
        render: (text) => <a href={`/admin/subdomains?domain=${text.domains.join('&domain=')}`}>{text.count}</a>,
      },
      {
        title: 'Vulnerabilities',
        dataIndex: 'vulnerabilities',
        key: 'vulnerabilities',
        ellipsis: true,
        sorter: (a, b) => a.vulnerabilities.count - b.vulnerabilities.count,
        sortOrder: sortedInfo.columnKey === 'vulnerabilities' && sortedInfo.order,
        render: (text) => <a href={`/admin/bugs?logUuid=${text.uuid}`}>{text.count}</a>,
      },
      {
        title: 'Start Date',
        dataIndex: 'created_at',
        key: 'created_at',
        ellipsis: true,
        render: (text) => moment(text).format('YYYY/MM/DD - HH:mm'),
        width: '170px'
      },
      {
        title: 'End Date',
        dataIndex: 'updated_at',
        key: 'updated_at',
        ellipsis: true,
        render: (text) => text && moment(text).format('YYYY/MM/DD - HH:mm'),
        width: '170px'
      },
      {
        title: 'Status',
        dataIndex: 'is_active',
        key: 'is_active',
        ellipsis: true,
        render: (text) => text ?
            <PauseCircleOutlined
              onClick={() => this.onCloseScanClick()}
              style={{ fontSize: '20px', marginLeft: '16px', color: '#fbc932', cursor: 'pointer' }}
            /> :
            <CheckCircleOutlined style={{ fontSize: '20px', marginLeft: '16px', color: 'green' }} />,
      },
    ]

    return (
      <>
        <HeaderScans handleAddNewScan={this.handleAddNewScan}/>
        {/* Page content */}
        <Container className="mt--7" fluid>
        {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <div style={{ position: 'absolute', width: '100%', paddingRight: '48px' }}>
                    <h3 className="mb-0" style={{ position: 'absolute' }}>Tool Scans</h3>
                    <div style={{ float: 'right' }}>Auto refresh is <Switch checked={this.state.autoRefresh} onChange={this.onAutoRefreshChange} /></div>
                  </div>
                  <br/>
                  <Row style={{ marginTop: '20px' }}>
                    <SearchFilter tags={this.state.tags} setTags={this.setTags} fetch={fetchScans} sortedInfo={this.state.sortedInfo} />
                  </Row>
                </CardHeader>
                <Space style={{ marginBottom: 16, marginLeft: 24 }}>
                  <Button onClick={this.clearAll}>Clear filters and sorters</Button>
                </Space>
                <Table
                  columns={columns}
                  pagination={pagination}
                  loading={this.props.scans.loading}
                  style={{ padding: '24px' }}
                  dataSource={(() => {
                    const scans = this.props.scans.scans || []
                    return scans.map((scan, index) => {
                      return {
                        key: index,
                        ...scan,
                        subdomains: {
                          toolName: scan.tool.name,
                          count: scan.subdomains,
                          domains: scan.domains.map((d) => d.name),
                        },
                        vulnerabilities: {
                          toolName: scan.tool.name,
                          createdAt: scan.created_at,
                          updatedAt: scan.updated_at,
                          count: scan.vulnerabilities,
                          uuid: scan.uuid,
                        },
                        domains: scan.domains.map((d) => d.name)
                      }
                    })
                  })()}
                  onChange={this.handleTableChange}
                />
              </Card>
            </div>
          </Row>
        </Container>
      </>
    )
  }
}

Scans.propTypes = {
  dispatch: PropTypes.func,
  scans: PropTypes.any,
}

const mapStateToProps = createStructuredSelector({
  scans: makeSelectScan(),
})

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Scans)
