import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
  Button,
  Card, CardHeader, Container, Row
} from "reactstrap"
import HeaderSubmissions from "../../components/Headers/HeaderSubmissions"
import { GeneralSubmissionDiv, SubmissionDiv } from "../../css/submission.style"
import { Input, Select, Table, Menu, Dropdown } from "antd"
import MDEditor, {commands} from '@uiw/react-md-editor'
import rehypeSanitize from "rehype-sanitize"
import {
  createSubmission,
  fetchIssues,
  fetchNextSubmissionPage,
  fetchSubmissions,
  updateSubmission
} from "../../actions/submissionActions"
import submission from "../../libs/submission"
import { createStructuredSelector } from "reselect"
import makeSelectSubmission from "../../selectors/submissionSelector"
import { BlockOutlined, CopyOutlined, EditOutlined, EllipsisOutlined } from "@ant-design/icons"
import moment from "moment"
import getParams from "../../utils/getParams"
import SearchFilter from "./SearchFilter"

const { Option } = Select
const { TextArea } = Input


class Submission extends React.Component {
  state = {
    submissionAddFormShown: false,
    submissionEditFormShown: false,
    issues: [],
    submission: {
      type: '',
      description: '',
      remediation: '',
      severity: '',
      url: '',
      request: '',
      platform: 'bugcrowd',
      step: '',
    },
    basicStep: '',
    replicationStepsParams: [],
    sortedInfo: null,
    pagination: {
      current: 1,
      total: 0,
    },
    tags: [],
  }

  markdownParts = ['title', 'description', 'severity', 'remediation', 'request']

  componentDidMount() {
    if (this.state.pagination.current) {
      this.props.dispatch(fetchNextSubmissionPage(this.state.pagination.current))
    } else {
      this.props.dispatch(fetchSubmissions())
    }

    if (!this.state.issues.length) {
      this.props.dispatch(fetchIssues())
    }
  }

  onNewSubmissionTypeChange = (selected) => {
    submission.fetchStep(selected.replaceAll(' ', '_')).then((response) => {
      const selectedIssue = this.props.submissions.issues.filter((i) => i.name === selected)[0]

      const replicationStepsValues = {}
      response.step.params.forEach((p) => {
        replicationStepsValues[p.name] = p.default || ''
      })

      this.setState({
        submission: {
          url: this.state.submission.url,
          request: this.state.submission.request,
          platform: this.state.submission.platform,
          type: selected,
          severity: selectedIssue.severity,
          description: Array.isArray(selectedIssue.description) ? selectedIssue.description.join('<br /><br />') : selectedIssue.description,
          remediation: Array.isArray(selectedIssue.remediation) ? selectedIssue.remediation.join('<br /><br />') : selectedIssue.remediation,
          step: response.step.content,
          ...replicationStepsValues,
        },
        basicStep: response.step.content,
        replicationStepsParams: response.step.params,
      }, () => { this.updateSteps() })
    })
  }

  onNewSubmissionUrlChange = (e) => {
    this.setState({
      submission: {
        ...this.state.submission,
        url: e.target.value,
      }
    }, () => { this.updateSteps() })
  }

  onNewSubmissionRequestChange = (e) => {
    this.setState({
      submission: {
        ...this.state.submission,
        request: e.target.value,
      }
    }, () => { this.updateSteps() })
  }

  onNewSubmissionReplicationStepsInputChange = (e, stepName) => {
    const submission = this.state.submission
    submission[stepName] = e.target.value

    this.setState({
      submission
    }, () => { this.updateSteps() })
  }

  onNewSubmissionReplicationStepsTextAreaChange = (e, stepName) => {
    const submission = this.state.submission
    submission[stepName] = e.target.value

    this.setState({
      submission
    }, () => { this.updateSteps() })
  }

  onSubmissionActionButtonClick = () => {
    const replicationStepsParams = {}
    this.state.replicationStepsParams.forEach((rs) => {
      replicationStepsParams[rs.name] = this.state.submission[rs.name] || ''
    })

    const payload = {
      url: this.state.submission.url,
      title: `${this.state.submission.type} on the ${this.state.submission.url}`,
      description: this.state.submission.step,
      platform: this.state.submission.platform,
      type: this.state.submission.type,
      request: this.state.submission.request,
      data: {
        replication_steps_params: replicationStepsParams,
      }
    }

    if (this.state.submissionEditFormShown) {
      this.props.dispatch(updateSubmission(this.state.submission.uuid, payload))
      this.newSubmissionEditingFormToggle()
    } else if (this.state.submissionAddFormShown) {
      this.props.dispatch(createSubmission(payload))
      this.newSubmissionAddingFormToggle()
    }
  }

  onNewSubmissionPlatformChanged = (selectedValue) => {
    this.setState({
      submission: {
        ...this.state.submission,
        platform: selectedValue,
      }
    })
  }

  onSubmissionEditIconClick = (editingUuid) => {
    const editingSubmission = this.props.submissions.submissions.filter((p) => p.uuid === editingUuid)[0]
    const selectedIssue = this.props.submissions.issues.filter((i) => i.name === editingSubmission.type)[0]

    submission.fetchStep(editingSubmission.type.replaceAll(' ', '_')).then((response) => {
      this.setState({
        submission: {
          uuid: editingUuid,
          url: editingSubmission.url,
          request: editingSubmission.request,
          platform: editingSubmission.platform,
          type: editingSubmission.type,
          severity: editingSubmission.severity,
          description: Array.isArray(selectedIssue.description) ? selectedIssue.description.join('<br /><br />') : selectedIssue.description,
          remediation: Array.isArray(selectedIssue.remediation) ? selectedIssue.remediation.join('<br /><br />') : selectedIssue.remediation,
          step: editingSubmission.description,
          ...editingSubmission.data.replication_steps_params
        },
        replicationStepsParams: response.step.params,
        submissionEditFormShown: true,
        basicStep: response.step.content,
      })
    })
  }

  onMDEditorChange = (value) => {
    const lastMarkdownValue = this.state.submission.step
    const currentMarkdownValue = value

    const willUpdateSubmissionData = {}
    const markdownParts = [...this.markdownParts, ...this.state.replicationStepsParams.map((p) => p.name)]

    markdownParts.forEach((p) => {
      const lastMarkdownPartValue = lastMarkdownValue.split(`[${p}]: <>`)[1]

      let currentMarkdownPartValue = currentMarkdownValue.split(`[${p}]: <>`)[1]
      if (!currentMarkdownPartValue) {
        willUpdateSubmissionData[p] = ''
        return
      }
      let splittedMarkDown = currentMarkdownPartValue.split('\n')
      splittedMarkDown = splittedMarkDown.filter((s) => s !== '\n' && s !== '' && s !== '  ')

      currentMarkdownPartValue = splittedMarkDown.join('\n')

      currentMarkdownPartValue = currentMarkdownPartValue.replaceAll('\n```\n', '').replaceAll('    - ', '')

      if (lastMarkdownPartValue !== currentMarkdownPartValue) {
        if (p === 'title') {
          const url = currentMarkdownPartValue.split('on the ')[1]
          willUpdateSubmissionData['url'] = url === '{{url}}' ? '' : url
        } else if (this.state.replicationStepsParams.map((p) => p.name).includes(p)) {
          let value = currentMarkdownPartValue
          const result = this.state.basicStep.split(`[${p}]: <>`)[1].split(`{{${p}}}`)

          result.forEach((r, index) => {
            if (r.startsWith('\n') || index === result.length -1) {
              r = r.replace('\n', '')
            }

            if (!value) return
            value = value.split(r)
            value = value[index === result.length -1 ? 0 : 1]
          })

          if (value) willUpdateSubmissionData[p] = value
        } else if (p === 'request') {
          willUpdateSubmissionData['request'] = currentMarkdownPartValue === `\`\`\`\n{{${p}}}\n\`\`\`` ? '' : currentMarkdownPartValue.replaceAll('```\n', '').replace('\n```', '')
        } else {
          willUpdateSubmissionData[p] = currentMarkdownPartValue === `{{${p}}}` ? '' : currentMarkdownPartValue
        }
      }
    })

    this.setState({
      submission: {
        ...this.state.submission,
        ...willUpdateSubmissionData,
        step: value,
      }
    })
  }

  onSubmissionDuplicateButtonClick = (submissionUuid = null) => {
    if (submissionUuid) {
      const duplicatingSubmission = this.props.submissions.submissions.filter((p) => p.uuid === submissionUuid)[0]
      const selectedIssue = this.props.submissions.issues.filter((i) => i.name === duplicatingSubmission.type)[0]

      submission.fetchStep(duplicatingSubmission.type.replaceAll(' ', '_')).then((response) => {
        this.setState({
          submission: {
            url: duplicatingSubmission.url,
            request: duplicatingSubmission.request,
            platform: duplicatingSubmission.platform,
            type: duplicatingSubmission.type,
            severity: duplicatingSubmission.severity,
            description: Array.isArray(selectedIssue.description) ? selectedIssue.description.join('<br /><br />') : selectedIssue.description,
            remediation: Array.isArray(selectedIssue.remediation) ? selectedIssue.remediation.join('<br /><br />') : selectedIssue.remediation,
            step: duplicatingSubmission.description,
            ...duplicatingSubmission.data.replication_steps_params
          },
          replicationStepsParams: response.step.params,
          submissionEditFormShown: false,
          submissionAddFormShown: true,
          basicStep: response.step.content,
        })
      })
    } else {
      const submission = this.state.submission
      delete submission['uuid']

      this.setState({
        submission: submission,
        submissionEditFormShown: false,
        submissionAddFormShown: true,
      })
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    if (pagination.current > Math.round(this.state.pagination.count / 10)) return

    this.setState({
      sortedInfo: sorter,
      pagination: {
        ...this.state.pagination,
        current: pagination.current
      },
    }, () => {
      const params = getParams([], this.state.sortedInfo, this.props.location, filters)

      this.props.dispatch(fetchNextSubmissionPage(pagination.current, params))
    })
  }

  newSubmissionAddingFormToggle = () => {
    this.setState({
      submissionAddFormShown: !this.state.submissionAddFormShown,
      submission: {
        type: '',
        description: '',
        remediation: '',
        severity: '',
        url: '',
        request: '',
        platform: 'bugcrowd',
        step: '',
      },
      basicStep: '',
      replicationStepsParams: [],
    })
  }

  newSubmissionEditingFormToggle = () => {
    this.setState({
      submissionEditFormShown: !this.state.submissionEditFormShown,
      submission: {
        type: '',
        description: '',
        remediation: '',
        severity: '',
        url: '',
        request: '',
        platform: 'bugcrowd',
        step: '',
      },
      basicStep: '',
      replicationStepsParams: [],
    })
  }

  updateSteps = () => {
    let mainStep = this.state.basicStep

    Object.entries(this.state.submission).forEach(([key, value]) => {
      if (value && this.state.basicStep.indexOf(`{{${key}}}`)) {
         mainStep = mainStep.replace(`{{${key}}}`, value)
       }
    })

    this.setState({
      submission: {
        ...this.state.submission,
        step: mainStep,
      },
    })
  }

  clickToCopyMarkdown = () => {
    const markdownParts = [...this.markdownParts, ...this.state.replicationStepsParams.map((p) => p.name)]

    let markdownContent = this.state.submission.step

    markdownParts.forEach((p) => {
      ['\n', '\n\n'].forEach((a) => {
        const splittedMD = markdownContent.split(`[${p}]: <>${a}`)
        let newMarkdownContent = ''

        splittedMD.forEach((m) => {
          let mdValue = m

          while (mdValue.startsWith(' ')) {
            mdValue = mdValue.replace(' ', '')
          }

          newMarkdownContent += mdValue
        })
        markdownContent = newMarkdownContent
      })
    })

    navigator.clipboard.writeText(markdownContent)
  }

  setTags = (tags) => {
    this.setState({
      tags,
    })
  }

  render() {
    const { pagination } = this.state
    if (this.props.submissions.count !== pagination.total) pagination.total = this.props.submissions.count

    let { sortedInfo } = this.state
    sortedInfo = sortedInfo || {}

    return (
      <>
        <HeaderSubmissions newSubmissionAddingFormToggle={this.newSubmissionAddingFormToggle} />
          <Container className="mt--7" fluid>
            <Row>
              <GeneralSubmissionDiv className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <h3 className="mb-0">Submission Craft</h3>
                    <br/>
                    {!this.state.submissionAddFormShown && !this.state.submissionEditFormShown ? (
                      <Row style={{ marginTop: '20px' }}>
                        <SearchFilter
                          tags={this.state.tags}
                          setTags={this.setTags}
                          fetch={fetchSubmissions}
                          sortedInfo={this.state.sortedInfo}
                          filters={['platform', 'title']}
                        />
                      </Row>
                    ) : null}
                  </CardHeader>

                  {this.state.submissionAddFormShown || this.state.submissionEditFormShown ? (
                    <SubmissionDiv>
                      <div className='modal-sub-div first'>
                        <div>
                          <b>Platform</b><br/>
                          <Select
                            placeholder='Select a platform'
                            optionFilterProp='children'
                            onChange={this.onNewSubmissionPlatformChanged}
                            value={this.state.submission.platform}
                          >
                            <Option value='bugcrowd'>Bugcrowd</Option>
                            <Option value='hackerone'>Hackerone</Option>
                            <Option value='synack'>Synack</Option>
                          </Select>
                        </div>
                        <div className='sub'>
                          <b>Select a submission type</b><br/>
                          <Select
                            showSearch
                            optionFilterProp='children'
                            onChange={this.onNewSubmissionTypeChange}
                            defaultValue={this.state.submission ? this.state.submission.type : null}
                            value={this.state.submission ? this.state.submission.type : null}
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {
                              this.props.submissions.issues.map((issue, index) => {
                                return (
                                  <Option key={index} value={issue.name}>{issue.name}</Option>
                                )
                              })
                            }
                          </Select>
                        </div>
                        <div className='sub'>
                          <b>Enter the vulnerability URL</b>
                          <Input
                            value={this.state.submission ? this.state.submission.url : null}
                            onChange={this.onNewSubmissionUrlChange}
                          />
                        </div>
                        <div className='sub'>
                          <b>Enter the vulnerability request</b>
                          <TextArea
                            value={this.state.submission.request}
                            onChange={this.onNewSubmissionRequestChange}
                            placeholder='POST /...  HTTP/1.1 ...'
                            autoSize={{ minRows: 15, maxRows: 15 }}
                          />
                        </div>
                        {this.state.replicationStepsParams.length ? (
                          <div className='replication-steps-params'>
                            <h2>Replication Step's Parameters</h2>
                            {this.state.replicationStepsParams.map((step) => {
                              return (
                                <div className='sub' key={step.name}>
                                  <b>{step.text}</b>
                                  {
                                    {
                                      'input':
                                        <Input
                                          value={this.state.submission && this.state.submission[step.name] ? this.state.submission[step.name] : ''}
                                          onChange={(e) => this.onNewSubmissionReplicationStepsInputChange(e, step.name)}
                                        />,
                                      'text':
                                        <TextArea
                                          value={this.state.submission && this.state.submission[step.name] ? this.state.submission[step.name] : ''}
                                          onChange={(e) => this.onNewSubmissionReplicationStepsTextAreaChange(e, step.name)}
                                          autoSize={{ minRows: 3, maxRows: 5 }}
                                        />
                                    }[step.type]
                                  }
                                </div>
                              )
                            })}
                          </div>
                        ) : null}
                      </div>
                      <div className='modal-sub-div second'>
                        {this.state.basicStep !== '' ? (
                          <div>
                            <div data-color-mode="light">
                              <MDEditor
                                height={550}
                                style={{ marginTop: '28px' }}
                                value={this.state.submission.step}
                                onChange={this.onMDEditorChange}
                                previewOptions={{
                                  rehypePlugins: [[rehypeSanitize]],
                                }}
                                highlightEnable={false}
                                renderTextarea={(props, { dispatch, onChange}) => {
                                  return (
                                    <textarea {...props} onChange={(e) => {
                                      dispatch && dispatch({ markdown: e.target.value });
                                      onChange && onChange(e);
                                    }}/>
                                  )
                                }}
                                commands={[
                                  commands.bold,
                                  commands.italic,
                                  commands.strikethrough,
                                  commands.hr,
                                  commands.title,
                                  commands.divider,
                                  commands.link,
                                  commands.quote,
                                  commands.code,
                                  commands.divider,
                                  commands.unorderedListCommand,
                                  commands.orderedListCommand,
                                  commands.checkedListCommand,
                                  commands.divider,
                                  {
                                    name: "Copy",
                                    keyCommand: "Copy",
                                    shortcuts: "Copy",
                                    buttonProps: { "title": "Copy Markdown" },
                                    icon: (
                                      <CopyOutlined />
                                    ),
                                    execute: (state, api) => {
                                      this.clickToCopyMarkdown()
                                    }
                                  }
                                ]}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className='empty-div'>
                            <b>You will see a markdown editor when you select submission type.</b>
                          </div>
                        )}
                      </div>
                      <div>
                        <Button
                          className='closeButton'
                          size="lg"
                          type="button"
                          onClick={this.state.submissionEditFormShown ? this.newSubmissionEditingFormToggle : this.newSubmissionAddingFormToggle}
                        >
                          Close
                        </Button>
                        {this.state.submissionEditFormShown ? (
                          <Button
                            className='duplicateButton'
                            size="lg"
                            type="button"
                            onClick={() => this.onSubmissionDuplicateButtonClick()}
                          >
                            Duplicate
                          </Button>
                        ) : null}
                        <Button
                          className='saveButton'
                          color="primary"
                          size="lg"
                          type="button"
                          onClick={this.onSubmissionActionButtonClick}
                          disabled={
                            !this.state.submission.url ||
                            !this.state.submission.type ||
                            !this.state.submission.platform
                          }
                        >
                          {this.state.submissionEditFormShown ? 'Update' : 'Save'}
                        </Button>
                      </div>
                    </SubmissionDiv>
                ) : (
                  <Table
                    columns={
                      [
                        {
                          title: 'Platform',
                          dataIndex: 'platform',
                          key: 'platform',
                          ellipsis: true,
                          editable: true,
                          width: 100,
                        },
                        {
                          title: 'Title',
                          dataIndex: 'title',
                          key: 'title',
                          ellipsis: true,
                          editable: true,
                          width: 600,
                        },
                        {
                          title: 'URL',
                          dataIndex: 'url',
                          key: 'url',
                          ellipsis: true,
                          editable: true,
                          width: 460,
                        },
                        {
                          title: 'Date',
                          dataIndex: 'date',
                          key: 'date',
                          sorter: (a, b) => moment(a.date) - moment(b.date),
                          sortOrder: sortedInfo.columnKey === 'date' && sortedInfo.order,
                          ellipsis: true,
                          render: (text) => moment(text).format('YYYY/MM/DD - HH:mm'),
                          width: 170,
                        },
                        {
                          title: 'Action',
                          dataIndex: 'uuid',
                          key: 'edit',
                          ellipsis: true,
                          render: (text) => (
                            <Dropdown
                              overlay={(
                                <Menu>
                                  <Menu.Item key="0">
                                    <div
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => this.onSubmissionEditIconClick(text)}
                                    >
                                      <EditOutlined /> Edit
                                    </div>
                                  </Menu.Item>
                                  <Menu.Item key="1">
                                    <div
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => this.onSubmissionDuplicateButtonClick(text)}
                                    >
                                      <BlockOutlined /> Duplicate
                                    </div>
                                  </Menu.Item>
                                </Menu>
                              )}
                              placement="bottomLeft"
                              trigger={['click']}
                              arrow
                            >
                              <EllipsisOutlined className='moreActionIcon' onClick={e => e.preventDefault()} />
                            </Dropdown>
                          )
                        },
                      ]
                    }
                    pagination={pagination}
                    loading={this.props.submissions.loading}
                    dataSource={(() => {
                      return this.props.submissions.submissions.map((submission, index) => {
                        return {
                          ...submission,
                          key: index,
                          date: submission.created_at,
                          platform: submission.platform.charAt(0).toUpperCase() + submission.platform.slice(1),
                        }
                      })
                    })()}
                    onChange={this.handleTableChange} />
                )}
              </Card>
            </GeneralSubmissionDiv>
          </Row>
        </Container>
      </>
    )
  }
}

Submission.propTypes = {
  dispatch: PropTypes.func,
  submissions: PropTypes.any,
}

const mapStateToProps = createStructuredSelector({
  submissions: makeSelectSubmission()
})

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Submission)
