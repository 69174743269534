import React from "react"
import { StyledInput, StyledTag } from "../../css/subdomain.style"
import { Col } from "reactstrap"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import prepareFilterData from "../../utils/filterData"

class SearchFilter extends React.Component {
  state = {
    inputVisible: false,
    inputValue: '',
    editInputIndex: -1,
    editInputValue: '',
  }

  filters = this.props.filters || ['program', 'domain', 'tool', 'subdomain']

  handleSearchInputChange = (e) => {
    this.setState({ inputValue: e.target.value.toLowerCase().replaceAll(/\s/g,'') })
  }

  handleInputConfirm = () => {
    const { inputValue } = this.state
    let { tags } = this.props

    if (!this.filters.includes(inputValue.split(':')[0])) {
      this.setState({
        inputVisible: false,
        inputValue: '',
      })
      return
    }

    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue]
    }

    this.setState({
      inputVisible: false,
      inputValue: '',
    }, () => {
      this.props.setTags(tags)
      this.props.dispatch(this.props.fetch(prepareFilterData(tags, this.props.sortedInfo)))
    })
  }

  handleEditInputConfirm = () => {
    const newTags = [...this.props.tags]
    this.setState(({ editInputIndex, editInputValue }) => {

      if (this.filters.includes(editInputValue.split(':')[0])) {
        newTags[editInputIndex] = editInputValue
      }

      this.props.setTags(newTags)

      return {
        editInputIndex: -1,
        editInputValue: '',
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
      }
    }, () => { this.props.dispatch(this.props.fetch(prepareFilterData(newTags, this.props.sortedInfo))) })
  }

  handleEditInputChange = e => {
    this.setState({ editInputValue: e.target.value.toLowerCase().replaceAll(/\s/g,'') })
  }

  handleClose = removedTag => {
    const tags = this.props.tags.filter(tag => tag !== removedTag)
    this.props.setTags(tags)
    this.props.dispatch(this.props.fetch(prepareFilterData(tags, this.props.sortedInfo)))
  }
  
  saveEditInputRef = input => {
    this.editInput = input
  }

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus())
  }

  saveInputRef = input => {
    this.input = input
  }
    
  render() {
    if (window.location.pathname === '/admin/subdomains' && this.filters.indexOf('is_http') === -1) {
      this.filters.push('is_http')
    }

    return (
      <Col lg="24" xl="12">
        {this.props.tags.map((tag, index) => {
          const prefix = tag.split(':')[0]

          if (this.state.editInputIndex === index) {
            return (
              <StyledInput
                ref={this.saveEditInputRef}
                key={tag}
                value={this.state.editInputValue}
                onChange={this.handleEditInputChange}
                onBlur={this.handleEditInputConfirm}
                onPressEnter={this.handleEditInputConfirm}
              />
            )
          }
    
          return (
            <StyledTag
              type={prefix}
              key={tag}
              closable={true}
              color={prefix === 'program' ? 'purple' : prefix === 'tool' ? 'gold' : 'blue'}
              onClose={() => this.handleClose(tag)}
            >
              <span
                onDoubleClick={e => {
                  this.setState({ editInputIndex: index, editInputValue: tag }, () => {
                    this.editInput.focus()
                  })
                  e.preventDefault()
                }}
              >
                {tag}
              </span>
            </StyledTag>
          )
        })}

        {this.state.inputVisible && (
          <StyledInput
            ref={this.saveInputRef}
            type="text"
            value={this.state.inputValue}
            onChange={this.handleSearchInputChange}
            onBlur={this.handleInputConfirm}
            onPressEnter={this.handleInputConfirm}
          />
        )}
        {!this.state.inputVisible && (
          <StyledTag onClick={this.showInput} color="volcano">
            <span>Add filter</span>
          </StyledTag>
        )}
      </Col>
    )
  }
}

SearchFilter.propTypes = {
  dispatch: PropTypes.func,
  fetch: PropTypes.func,
  tags: PropTypes.any,
  setTags: PropTypes.func,
  sortedInfo: PropTypes.any,
  filters: PropTypes.any,
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  }
}

export default connect(null, mapDispatchToProps)(SearchFilter)
