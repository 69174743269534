import request from "../utils/request"

const vulnerability = {
  fetch(params = null) {
    let url = '/vulnerabilities/'
    if (params) url += `?${params}`

    return request
      .get(url)
      .then((r) => r.data)
  },
  nextPage(page, params = null) {
    let url = `vulnerabilities/?page=${page}`
    if (params) url += `&${params}`

    return request
      .get(url)
      .then((r) => r.data)
  },
}

export default vulnerability