import React from "react"

// reactstrap components
import { Container, Row, Col } from "reactstrap"

class HeaderVulnerabilities extends React.Component {

  render() {
    return (
      <>
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row>
                <Col lg="24" xl="12">
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    )
  }
}

export default HeaderVulnerabilities
