import {
  CREATE_SUBMISSION_START,
  FETCH_STEP_START,
  FETCH_ISSUE_START,
  FETCH_SUBMISSIONS_START, FETCH_NEXT_SUBMISSION_PAGE_START,
  UPDATE_SUBMISSION_START
} from "../constants/submissionConstant"

export function fetchIssues() {
  return {
    type: FETCH_ISSUE_START,
  }
}

export function fetchStep(name) {
  return {
    type: FETCH_STEP_START,
    name,
  }
}

export function createSubmission(payload) {
  return {
    type: CREATE_SUBMISSION_START,
    payload,
  }
}

export function fetchSubmissions(params) {
  return {
    type: FETCH_SUBMISSIONS_START,
    params,
  }
}

export function fetchNextSubmissionPage(page, params) {
  return {
    type: FETCH_NEXT_SUBMISSION_PAGE_START,
    page,
    params,
  }
}

export function updateSubmission(submissionUuid, payload) {
  return {
    type: UPDATE_SUBMISSION_START,
    submissionUuid,
    payload,
  }
}
