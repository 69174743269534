export const FETCH_ISSUE_START = 'FETCH_ISSUE_START'
export const FETCH_ISSUE_SUCCESS = 'FETCH_ISSUE_SUCCESS'

export const FETCH_STEP_START = 'FETCH_STEP_START'
export const FETCH_STEP_SUCCESS = 'FETCH_STEP_SUCCESS'

export const CREATE_SUBMISSION_START = 'CREATE_SUBMISSION_START'
export const CREATE_SUBMISSION_SUCCESS = 'CREATE_SUBMISSION_SUCCESS'

export const FETCH_SUBMISSIONS_START = 'FETCH_SUBMISSIONS_START'
export const FETCH_SUBMISSIONS_SUCCESS = 'FETCH_SUBMISSIONS_SUCCESS'

export const FETCH_NEXT_SUBMISSION_PAGE_START = 'FETCH_NEXT_SUBMISSION_PAGE_START'
export const FETCH_NEXT_SUBMISSION_PAGE_SUCCESS = 'FETCH_NEXT_SUBMISSION_PAGE_SUCCESS'

export const UPDATE_SUBMISSION_START = 'UPDATE_SUBMISSION_START'
export const UPDATE_SUBMISSION_SUCCESS = 'UPDATE_SUBMISSION_SUCCESS'
