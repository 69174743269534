import request from 'utils/request'

const scan = {
  fetch(params = null) {
    let url = '/tool_scans'
    if (params) url += `?${params}`

    return request
        .get(url)
        .then((r) => r.data)
  },
  nextPage(page, params = null) {
    let url = `tool_scans?page=${page}`
    if (params) url += `&${params}`

    return request
      .get(url)
      .then((r) => r.data)
  },
  startSubdomainScan(data) {
    return request
      .post('scan/subdomain', data)
      .then((r) => r.data)
  },
  startNucleiScan(data) {
    return request
      .post('scan/nuclei', data)
      .then((r) => r.data)
  },
  startFfufScan(data) {
    return request
      .post('scan/ffuf', data)
      .then((r) => r.data)
  },
  startNucleiScanToAllSubdomains(data) {
    return request
      .post('scan/nuclei/all/subdomains', data)
      .then((r) => r.data)
  },
}

export default scan
