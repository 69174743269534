import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Checkbox, Input as AntInput, Button } from "antd"
import '../../assets/css/NewDomainView.css'
import domain from "../../libs/domain"

class NewDomainView extends React.Component {
  state = {
    checkLoading: false,
    showErrorMessage: false,
    errorMessage: '',
  }

  checkSubdomainOfDomain = () => {
    this.setState({
      checkLoading: true,
    })

    domain.checkTheURL(this.props.newDomain.url).then((response) => {
      this.props.changeVisibilityOfCreateButton(!response['exist'])

      this.setState({
        showErrorMessage: response['exist'],
        errorMessage: response['exist'] ? `This domain is in the ${response['program']['name']} program.` : '',
        checkLoading: false,
      })
    })
  }

  render() {
    return (
      <div style={this.props.style}>
        <div className='modal-sub-div'>
          <span>URL</span>
          <div>
            <AntInput
              className={`domain-url-input${this.state.checkLoading ? '-checking' : ''}`}
              value={this.props.newDomain ? this.props.newDomain.url : null}
              onChange={(e) => this.props.onNewDomainUrlChanged(e, this.props.newDomain.id)}
            />
            <Button
              className='domain-url-check-button'
              type="primary"
              disabled={this.props.newDomain && this.props.newDomain.url && this.props.newDomain.url.length ? false : true}
              loading={this.state.checkLoading}
              onClick={this.checkSubdomainOfDomain}
            >
              Check
            </Button>
            {this.state.showErrorMessage ? (
              <span className='error-message-span'>{this.state.errorMessage}</span>
            ) : null}
          </div>
        </div>
        <div className='modal-sub-div'>
          <Checkbox
            onChange={(e) => this.props.onNewDomainBountyChange(e, this.props.newDomain.id)}
            checked={this.props.newDomain ? this.props.newDomain.bounty : false}
          >
            Bounty
          </Checkbox>

          <Checkbox
            onChange={(e) => this.props.onNewDomainFullScopeChange(e, this.props.newDomain.id)}
            checked={this.props.newDomain ? this.props.newDomain.full_scope : false}
          >
            Full Scope
          </Checkbox>
        </div>
      </div>
    )
  }
}

NewDomainView.propTypes = {
  dispatch: PropTypes.func,
  newDomain: PropTypes.any,
  onNewDomainUrlChanged: PropTypes.func,
  onNewDomainBountyChange: PropTypes.func,
  onNewDomainFullScopeChange: PropTypes.func,
  style: PropTypes.any,
  changeVisibilityOfCreateButton: PropTypes.any,
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  }
}

export default connect(null, mapDispatchToProps)(NewDomainView)
