import React from "react"

// reactstrap components
import { Container, Row, Col, Button } from "reactstrap"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { createStructuredSelector } from "reselect"
import makeSelectSubmission from "../../selectors/submissionSelector"


class HeaderSubmissions extends React.Component {

  render() {
    return (
      <>
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row>
                <Col lg="24" xl="12">
                  <Button
                    color="success"
                    size="lg"
                    type="button"
                    onClick={this.props.newSubmissionAddingFormToggle}
                  >
                    + Create New Submission
                  </Button>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    )
  }
}

HeaderSubmissions.propTypes = {
  dispatch: PropTypes.func,
  submissions: PropTypes.any,
  newSubmissionAddingFormToggle: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  submissions: makeSelectSubmission()
})

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderSubmissions)
