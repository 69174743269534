import {all, call, put, takeEvery} from "redux-saga/effects"
import { notification } from "antd"
import {
  FETCH_FFUF_WORDLIST,
  FETCH_FFUF_WORDLIST_SUCCESS,
  FETCH_NUCLEI_TEMPLATES, FETCH_NUCLEI_TEMPLATES_SUCCESS,
  FETCH_TOOL_START,
  FETCH_TOOL_SUCCESS
} from "../constants/toolConstant"
import tool from "../libs/tool"

export function* fetchTools(data) {
  try {
    const response = yield call(tool.fetch, data.toolType)

    yield put({
      type: FETCH_TOOL_SUCCESS,
      payload: response,
    })
  } catch (error) {
    notification['error']({
      message: 'Unexpected error',
    })
  }
}

export function* fetchNucleiTemplates() {
  try {
    const response = yield call(tool.fetchNucleiTemplates)

    yield put({
      type: FETCH_NUCLEI_TEMPLATES_SUCCESS,
      payload: response,
    })
  } catch (error) {
    notification['error']({
      message: 'Unexpected error',
    })
  }
}

export function* fetchFfufWordlist() {
  try {
    const response = yield call(tool.fetchFfufWordlist)

    yield put({
      type: FETCH_FFUF_WORDLIST_SUCCESS,
      payload: response,
    })
  } catch (error) {
    notification['error']({
      message: 'Unexpected error',
    })
  }
}

export function* watchFetchTools() {
  yield takeEvery(FETCH_TOOL_START, fetchTools)
}

export function* watchFetchNucleiTemplates() {
  yield takeEvery(FETCH_NUCLEI_TEMPLATES, fetchNucleiTemplates)
}

export function* watchFetchFfufWordlist() {
  yield takeEvery(FETCH_FFUF_WORDLIST, fetchFfufWordlist)
}

export default function* rootSaga() {
  yield all([
      watchFetchTools(),
      watchFetchNucleiTemplates(),
      watchFetchFfufWordlist(),
  ])
}
