/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react"
import { Provider } from "react-redux"
import { createStore, applyMiddleware } from "redux"
import createSagaMiddleware from 'redux-saga'
import ReactDOM from "react-dom"
import { BrowserRouter, Route } from "react-router-dom"
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'react-router-redux'
import { Map } from 'immutable'
import createReducer from "./reducers/index"
import userRootSaga from './sagas/userSaga'
import programRootSaga from './sagas/programSaga'
import domainRootSaga from './sagas/domainSaga'
import subdomainRootSaga from './sagas/subdomainSaga'
import scanRootSaga from './sagas/scanSaga'
import toolRootSaga from './sagas/toolSaga'
import vulnerabilityRootSaga from './sagas/vulnerabilitySaga'
import submissionRootSaga from './sagas/submissionSaga'

import "assets/plugins/nucleo/css/nucleo.css"
import "@fortawesome/fontawesome-free/css/all.min.css"
import "assets/scss/argon-dashboard-react.scss"

import App from "./layouts/App"

require('dotenv').config()

const sagaMiddleware = createSagaMiddleware()
export const store = createStore(
  createReducer(),
  Map(),
  applyMiddleware(...[
    sagaMiddleware,
    routerMiddleware(createBrowserHistory()),
  ])
)
sagaMiddleware.run(userRootSaga)
sagaMiddleware.run(programRootSaga)
sagaMiddleware.run(domainRootSaga)
sagaMiddleware.run(subdomainRootSaga)
sagaMiddleware.run(scanRootSaga)
sagaMiddleware.run(toolRootSaga)
sagaMiddleware.run(vulnerabilityRootSaga)
sagaMiddleware.run(submissionRootSaga)
store.runSaga = sagaMiddleware.run
store.asyncReducers = {}

if (module.hot) {
  module.hot.accept('./reducers/index', () => {
    import('./reducers/index').then((reducerModule) => {
      const createReducers = reducerModule.default
      const nextReducers = createReducers(store.asyncReducers)

      store.replaceReducer(nextReducers)
  })
})
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Route render={(props) => <App {...props} />} />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
)
