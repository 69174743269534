import {
  FETCH_USER_START, LOGIN_START
} from "../constants/userConstant"


export function loginStart(data) {
  return {
    type: LOGIN_START,
    data,
  }
}

export function fetchUser() {
  return {
    type: FETCH_USER_START,
  }
}
