import addSortedInfo from "./addSortParams"

export default function prepareFilterData(tags, sortedInfo) {
  let params = ''
  tags.forEach((t, index) => {
    const splited = t.split(':')
    params += `${splited[0]}=${splited[1]}`

    if (tags.length > 1 && index !== tags.length - 1) params += '&'
  })

  params = addSortedInfo(params, sortedInfo)

  return params
}
