import request from 'utils/request'

const domain = {
  fetch(params = null) {
    let url = 'domains'
    if (params) url += `?${params}`

    return request
      .get(url)
      .then((r) => r.data)
  },
  update(programUuid, domainUuid, data) {
    return request
        .patch(`/programs/${programUuid}/domains/${domainUuid}`, data)
        .then((r) => r.data)
  },
  create(programUuid, data) {
    return request
        .post(`programs/${programUuid}/domains`, data)
        .then((r) => r.data)
  },
  filteredDomains(programUuidList) {
    return request
      .post('/programs/domains', {programs: programUuidList})
      .then((r) => r.data)
  },
  nextPage(page, params = null) {
    let url = `domains?page=${page}`
    if (params) url += `&${params}`

    return request
      .get(url)
      .then((r) => r.data)
  },
  batchCreateDomain(data) {
    return request
      .post('/programs/domains/create/bulk', data)
      .then((r) => r.data)
  },
  batchUpdateDomain(data) {
    return request
      .patch('/programs/domains/create/bulk', data)
      .then((r) => r.data)
  },
  checkTheURL(url) {
    return request
      .get(`domains/check?url=${url}`)
      .then((r) => r.data)
  },
}

export default domain
