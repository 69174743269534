import PropTypes from 'prop-types'
import { connect } from "react-redux"
import { Redirect, Route, Switch } from "react-router-dom"
import { createStructuredSelector } from 'reselect'
import AdminLayout from "./Admin"
import AuthLayout from "./Auth"
import React from "react"
import makeSelectUser from "../selectors/userSelector"
import { fetchUser } from '../actions/userAction'
import { fetchPrograms } from "../actions/programAction"
import makeSelectProgram from "../selectors/programSelector"

class App extends React.PureComponent {
  componentDidMount() {
    if (this.props.location.pathname === '/auth/login') return;

    if (this.props.user.user === null) {
      this.props.dispatch(fetchUser())
    }

    if (!this.props.programs.programs.length) {
      this.props.dispatch(fetchPrograms())
    }
  }

  render() {
    return (
      <div>
        {localStorage.token ? (
          <Switch>
            <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
            <Redirect from="/" to="/admin/index" />
          </Switch>
        ) : (
          <Switch>
            <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
            <Redirect from="*" to="/auth/index" />
          </Switch>
        )}
      </div>
    )
  }
}

App.propTypes = {
  user: PropTypes.any,
  dispatch: PropTypes.func,
  location: PropTypes.any,
  programs: PropTypes.any,
}

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
  programs: makeSelectProgram(),
})

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
