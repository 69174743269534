import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {Button, Select, Steps, Switch} from "antd"
import { InfoCircleOutlined, PlusCircleOutlined, SmileOutlined } from "@ant-design/icons"
import NewProgramView from "./NewProgramView"
import NewDomainView from "./NewDomainView"
import program from "../../libs/program"
import domain from "../../libs/domain"
import { fetchTools } from "../../actions/toolActions"
import { startSubdomainScan } from "../../actions/scanActions"
import ProgramSelection from "./ProgramSelection"

const { Step } = Steps
const { Option } = Select


class QuickPageSteps extends React.Component {
  state = {
    newProgram: {
      name: null,
      briefUrl: null,
      platform: null
    },
    newDomains: [{
      id: 0,
      url: null,
      full_scope: true,
      bounty: true
    }],
    newScan: {
      tools: [],
      dynamic: {
        scan: true,
        read: false,
      },
    },
    currentStep: 0,
    currentUpdateStep: null,
  }

  steps = [
    {
      title: 'Create a new program',
      type: 'program',
    },
    {
      title: 'Create new domain/domains',
      type: 'domain',
    },
    {
      title: 'Start a scan',
      type: 'scan',
    },
    {
      title: 'Done',
      type: 'done',
    },
  ]

  onNewProgramNameChanged = (e) => {
    this.setState({
      newProgram: {
        ...this.state.newProgram,
        name: e.target.value,
      },
    });
  }

  onNewProgramBriefChanged = (e) => {
    this.setState({
      newProgram: {
        ...this.state.newProgram,
        briefUrl: e.target.value,
      },
    });
  }

  onNewProgramPlatformChanged = (selected) => {
    this.setState({
      newProgram: {
        ...this.state.newProgram,
        platform: selected,
      },
    });
  }

  onNewDomainUrlChanged = (e, id) => {
    const domains = this.state.newDomains

    const domainIndex = domains.findIndex((d) => d.id === id)
    domains[domainIndex].url = e.target.value

    this.setState({
      newDomains: domains,
    })
  }

  onNewDomainBountyChange = (e, id) => {
    const domains = this.state.newDomains

    const domainIndex = domains.findIndex((d) => d.id === id)
    domains[domainIndex].bounty = e.target.checked

    this.setState({
      newDomains: domains,
    })
  }

  onNewDomainFullScopeChange = (e, id) => {
    const domains = this.state.newDomains

    const domainIndex = domains.findIndex((d) => d.id === id)
    domains[domainIndex].full_scope = e.target.checked

    this.setState({
      newDomains: domains,
    })
  }

  onNewDomainAddClick = () => {
    const domains = this.state.newDomains.sort((a, b) => a.id - b.id)
    const newId = domains[domains.length - 1].id + 1

    const newDomain = {
      id: newId,
      url: null,
      full_scope: true,
      bounty: true
    }

    domains.push(newDomain)
    this.setState({
      newDomains: domains,
    })
  }

  onNewScanToolsChange = (selectedList) => {
    this.setState({
      newScan: {
        ...this.state.newScan,
        tools: selectedList,
      },
    })
  }

  onNewScanDynamicChange = (value) => {
    this.setState({
      newScan: {
        ...this.state.newScan,
        dynamic: {
          ...this.state.newScan.dynamic,
          scan: value
        },
      },
    })
  }

  onNewDynamicReadChange = (value) => {
    this.setState({
      newScan: {
        ...this.state.newScan,
        dynamic: {
          ...this.state.newScan.dynamic,
          read: value
        },
      },
    })
  }

  onProgramSelectionChange = (selectedUuid) => {
    const program = this.props.programs.programs.find((p) => p.uuid === selectedUuid)

    this.setState({
      newProgram: {
        uuid: program.uuid,
        name: program.name,
        briefUrl: program.brief_url,
        platform: program.platform,
      },
    })
  }

  deleteDomain = (domainId) => {
    let domains = this.state.newDomains

    const domainIndex = domains.findIndex((d) => d.id === domainId)
    if (domains[domainIndex].uuid) {
      domains[domainIndex].delete = true
    } else {
      domains = this.state.newDomains.filter((d) => d.id !== domainId)
    }


    this.setState({
      newDomains: domains,
    })
  }

  makeNewDomainList = (response, domainList) => {
    const newDomains = []

    response.forEach((r) => {
      let d = domainList.filter((n) => n.url === r.url && n.bounty === r.bounty && n.full_scope === r.full_scope)
      if (d) d = d[0]

      newDomains.push({
        ...r,
        id: d.id,
      })
    })

    return newDomains
  }

  next() {
    const currentStep = this.state.currentStep + 1

    if (this.steps[this.state.currentStep].type === 'program') {
      if (!this.props.newProgram) {
        this.setState({
          currentStep,
        })
        return
      }

      const data = {
        name: this.state.newProgram.name,
        platform: this.state.newProgram.platform,
        brief_url: this.state.newProgram.briefUrl
      }

      if (this.state.currentUpdateStep === this.state.currentStep) {
        program.update(this.state.newProgram.uuid, data).then((response) => {
          this.setState({
            currentStep,
          })
        })
      } else {
        program.create(data).then((response) => {
          this.setState({
            newProgram: {
              ...this.state.newProgram,
              uuid: response.uuid,
            },
            currentStep,
          })
        })
      }
    } else if (this.steps[this.state.currentStep].type === 'domain') {
      const data = {
        program_uuid: this.state.newProgram.uuid,
        domains: this.state.newDomains,
      }

      if (this.state.currentUpdateStep === this.state.currentStep) {
        domain.batchUpdateDomain(data).then((response) => {
          const newDomains = this.makeNewDomainList(response, this.state.newDomains)

          this.setState({
            currentStep,
            newDomains,
          })

          this.props.dispatch(fetchTools('subdomain'))
        })
      } else {
        domain.batchCreateDomain(data).then((response) => {
          const newDomains = this.makeNewDomainList(response, this.state.newDomains)

          this.setState({
            newDomains,
            currentStep,
          })

          this.props.dispatch(fetchTools('subdomain'))
        })
      }
    } else if (this.steps[this.state.currentStep].type === 'scan') {
      const data = {
        programs: [this.state.newProgram.uuid],
        domains: this.state.newDomains.map((d) => d.uuid),
        ...this.state.newScan,
      }
      this.props.dispatch(startSubdomainScan(data))

      this.setState({
        currentStep,
        newProgram: {
          name: null,
          briefUrl: null,
          platform: null
        },
        newDomains: [{
          id: 0,
          url: null,
          full_scope: true,
          bounty: true
        }],
        newScan: {
          tools: [],
          dynamic: {
            scan: true,
            read: false,
          },
        },
      })
    }
  }

  prev() {
    const currentStep = this.state.currentStep - 1
    this.setState({
      currentStep,
      currentUpdateStep: currentStep,
    })
  }

  render() {
    return (
      <div style={{ padding: '8px' }}>
        <Steps current={this.state.currentStep}>
          {this.steps.map((s) => {
            const title = !this.props.newProgram && this.steps[this.state.currentStep].type === 'program' ? 'Select a program' : s.title

            return (
              <Step key={title} title={title} icon={s.type === 'done' ? <SmileOutlined /> : ''} />
            )
          })}
        </Steps>
        <div className="steps-content">
          {this.steps[this.state.currentStep].type === 'program' ? (
            this.props.newProgram ? (
              <NewProgramView
                style={{ padding: '16px' }}
                newProgram={this.state.newProgram}
                onNewProgramNameChanged={this.onNewProgramNameChanged}
                onNewProgramBriefChanged={this.onNewProgramBriefChanged}
                onProgramPlatformChanged={this.onNewProgramPlatformChanged}
              />
            ) : (
              <ProgramSelection
                programs={this.props.programs.programs}
                showSelectAllProgramsCheckbox={false}
                onProgramSelectionChange={this.onProgramSelectionChange}
                selectedPrograms={this.state.newProgram.uuid}
                mode={null}
                style={{ marginBottom: '24px' }}
              />
            )
          ) : null}

          {this.steps[this.state.currentStep].type === 'domain' ? (
            <>
              {this.state.newDomains.filter((d) => !d.delete).map((domain) => {
                return (
                  <div key={domain.id}>
                    <NewDomainView
                      style={{ padding: '16px 16px 8px 16px' }}
                      newDomain={domain}
                      onNewDomainUrlChanged={this.onNewDomainUrlChanged}
                      onNewDomainBountyChange={this.onNewDomainBountyChange}
                      onNewDomainFullScopeChange={this.onNewDomainFullScopeChange}
                    />
                    <Button
                      type="primary"
                      onClick={() => this.deleteDomain(domain.id)}
                      style={{ background: '#D82B00', borderColor: '#D82B00', margin: '8px 16px 16px 16px' }}
                    >
                      Delete Domain
                    </Button>
                    <hr style={{ margin: '8px 16px 8px 16px' }}/>
                  </div>
                )
              })}
              <div className='add-new-domain-div' onClick={this.onNewDomainAddClick}>
                <PlusCircleOutlined style={{ padding: '0 0 16px 16px', color: '#eb2f96' }} /> Add a new domain
              </div>
            </>
          ) : null}

          {this.steps[this.state.currentStep].type === 'scan' ? (
            <div style={{ padding: '16px 16px 8px 16px' }}>
              <div className='modal-sub-div'>
                <b>Select tools</b><br/>
                <div style={{ marginBottom: '4px' }}><InfoCircleOutlined /> <span>If you do not select any tool, will be scanned with all tools.</span></div>
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select"
                  defaultValue={this.state.newScan.tools}
                  onChange={this.onNewScanToolsChange}
                >
                  {
                  this.props.tools.tools.map((tool, index) => {
                    return (
                      <Option key={index} value={tool.uuid}>{tool.name}</Option>
                    )
                  })
                }
                </Select>
              </div>
              <div className="modal-sub-div">
                Dynamic scan is <Switch checked={this.state.newScan.dynamic.scan} onChange={this.onNewScanDynamicChange} />
              </div>
              <div className="modal-sub-div">
                Dynamic read is <Switch checked={this.state.newScan.dynamic.read} onChange={this.onNewDynamicReadChange} />
              </div>
            </div>
          ) : null}

          {this.steps[this.state.currentStep].type === 'done' && (
            <div style={{ textAlign: 'center', padding: '32px' }}>
              <iframe
                src="https://giphy.com/embed/fxsqOYnIMEefC"
                frameBorder="0"
                className="giphy-embed"
                allowFullScreen
                title='Congrats'
              /><br />
              <b>Congrats! Scan will start.</b>
            </div>
          )}
        </div>
        <div className="steps-action">
          {this.state.currentStep < this.steps.length - 1 && (
            <Button
              type="primary"
              onClick={() => this.next()}
              disabled={
                (this.steps[this.state.currentStep].type === 'program' && (
                    !this.state.newProgram.name ||
                    !this.state.newProgram.briefUrl ||
                    !this.state.newProgram.platform
                )) ||
                (this.steps[this.state.currentStep].type === 'domain' && this.state.newDomains.filter((d) => !d.url).length)
              }
            >
              Next
            </Button>
          )}
          {this.state.currentStep > 0 && this.state.currentStep < this.steps.length - 1 && (
            <Button style={{ marginLeft: 8 }} onClick={() => this.prev()}>
              Previous
            </Button>
          )}
        </div>
      </div>
    )
  }
}

QuickPageSteps.propTypes = {
  dispatch: PropTypes.func,
  tools: PropTypes.any,
  programs: PropTypes.any,
  newProgram: PropTypes.any,
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  }
}

export default connect(null, mapDispatchToProps)(QuickPageSteps)
